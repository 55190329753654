import { Button, TextField } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';

const LocationForm = (props) => {
  const { handleChange, handleBlur, touched, errors, result, index, deleteLocation } = props;
  return (
    <div className="link">
      <TextField
        margin="dense"
        variant="outlined"
        placeholder="Enter location"
        fullWidth
        id={`location[${index}]`}
        label="Location"
        value={result}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.location?.[index] ? errors.location?.[index] : ''}
        error={touched.location?.[index] && Boolean(errors.location?.[index])}
        required
      ></TextField>
      <Button variant="text" onClick={() => deleteLocation(index)}>
        <DeleteOutline color="error" />
      </Button>
    </div>
  );
};

export default LocationForm;
