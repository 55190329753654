import React from "react";
import LocationOn  from '@material-ui/icons/LocationOn'
import Language  from "@material-ui/icons/Language";
import  Twitter  from "@material-ui/icons/Twitter";
import  YouTube from "@material-ui/icons/YouTube";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getClickableLink } from "../utils/getClickableLink";

const MiddleNav = ({ brandDetails }) => {
    const breakPoint1 = useMediaQuery("(min-width: 835px)");
    
    const navContent: { icon: JSX.Element; text: string; link: string }[] = [];

    if (brandDetails?.website) {
        navContent.push({
            icon: <Language fontSize="large" color="primary" />, 
            text: brandDetails.website,
            link: getClickableLink(brandDetails.website),
        });
    }

    brandDetails?.links?.forEach((result) => {
        switch (result.title.toLowerCase()) {
            case "twitter":
                navContent.push({
                    icon: <Twitter fontSize="large" color="primary" />, 
                    text: result.url,
                    link: getClickableLink(result.url),
                });
                break;
            case "youtube":
                navContent.push({
                    icon: <YouTube fontSize="large" color="primary" />, 
                    text: result.url,
                    link: getClickableLink(result.url),
                });
                break;
            default:
                break;
        }
    });

    if (brandDetails?.location?.length > 0) {
        navContent.push({
            icon: <LocationOn fontSize="large" color="primary" />, 
            text: "Where to Find Us",
            link: getClickableLink(brandDetails?.location[0]),
        });
    }

    return (
        <div
            style={{
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: breakPoint1 ? "50px" : "35px",
                alignItems: "center",
            }}
        >
            {navContent.map((item, index) => (
                <div
                    key={index}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            height: "45px",
                            width: "45px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {item.icon}
                    </div>
                    {breakPoint1 && (
                        <a target="_blank" rel="noopener noreferrer" href={item.link}>
                            <p
                                style={{
                                    fontSize: "20px",
                                    color: "#039FAE",
                                    textDecoration: "underline",
                                }}
                            >
                                {item.text}
                            </p>
                        </a>
                    )}
                </div>
            ))}
        </div>
    );
};

export default MiddleNav;
