// External Dependencies
import TOSDialog from '../static/TOSDialog';
import DisclaimerDialog from '../static/Disclaimer';
// import PrivacyPolicy from '../static/PrivacyPolicy';
import React, { useContext, useState } from 'react';

import { LinkButton } from 'shared/styled-components';
import UserPermissionDialog from '../profile/UserPermissionDialog';
import { SessionContext } from 'session/SessionContext';

// Component Definition
const Footer = () => {
  const [dialogType, setDialogType] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useContext(SessionContext);

  const hideModal = () => setIsOpen(false);
  const showModal = ({ type }) => {
    setDialogType(type);
    setIsOpen(true);
  };

  const showTOSModal = () => showModal({ type: 'tos' });
  const showDisclaimerModal = () => showModal({ type: 'disclaimer' });
  const showPermissionModal = () => showModal({ type: 'permission' });

  return (
    <div style={{ background: '#00A0B0', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 60 }}>
      <TOSDialog onClose={hideModal} open={isOpen && dialogType === 'tos'} />
      <DisclaimerDialog onClose={hideModal} open={isOpen && dialogType === 'disclaimer'} />
      <UserPermissionDialog onClose={hideModal} open={isOpen && dialogType === 'permission'} />

      <div className="footer">
        <p style={{ color: 'white' }}>
          <LinkButton style={{ color: 'white', textDecoration: 'none' }} onClick={showTOSModal}>Terms of Service</LinkButton>
          <span className="spacer">|</span>
          <LinkButton style={{ color: 'white', textDecoration: 'none' }} onClick={showDisclaimerModal}>Disclaimer</LinkButton>
          {user && (
            <>
              <span className="spacer">|</span>
              <LinkButton style={{ color: 'white', textDecoration: 'none' }} onClick={showPermissionModal}>Permissions</LinkButton>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default Footer;
