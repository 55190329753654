// External Dependencies
import FormGroup from '@material-ui/core/FormGroup';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import {ErrorText, FlexRow} from 'shared/styled-components';
import {LoginForm as LoginFormInterface} from 'shared/validation';
import SafeButton from "../SafeButton";
import InputAdornment from "@material-ui/core/InputAdornment";

/**
 * @callback onChange
 * @param {Object} event - The input event object describing the DOM element that was modified.
 *  We're only concerned with two attributes from the target element:
 *  id (identifies the textfield that was modified), and value (the new text value)
 */

/**
 * Displays a pair of textfields to log into our app.
 * @param {Object} form - The data used for each textfield. Key names should match the ids used here.
 * @param {onChange} onChange - Callback function when any field is modified
 * @param {boolean} hideGlobalError - When true we do not display any general error inside form.
 * @param {boolean} otpDisable - When true we do not display any general error inside form.
 *  Errors at the field level are always displayed.
 */
const LoginFormGroup = ({
                            form: {error, phoneNumber, phoneNumberError, otp, otpError},
                            hideGlobalError = false, otpDisable = true,
                            onChange = (_) => {
                            }, sendOtp
                        }: LoginFormGroupProps) => {
    const showError = !hideGlobalError && Boolean(error) && typeof error === 'string';
    return (
        <FormGroup>
            {showError && <ErrorText>{error}</ErrorText>}
            <FlexRow  style={{width: '100%'}}>
                <div  style={{width: '70%'}}>
                    <TextField
                        error={Boolean(phoneNumberError)}
                        fullWidth
                        id="phoneNumber"
                        label="Phone Number"
                        onChange={onChange}
                        value={phoneNumber}
                        style={{marginRight: "1em"}}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    +1
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>
                <div style={{width: '30%'}}>
                    <SafeButton
                        //disabled={otpButtonDisable}
                        isSubmitting={false}
                        color="primary"
                        fullWidth
                        onChange={onChange}
                        style={{
                            background: '#00A0B0',
                            color: 'white',
                            borderRadius: 4,
                            marginTop: '.9em',
                            marginLeft: '.2em'
                        }}
                        variant="outlined"
                        onClick={sendOtp}
                    >
                        Send Otp
                    </SafeButton>
                </div>
            </FlexRow>
            <TextField
                disabled={otpDisable}
                error={Boolean(otpError)}
                fullWidth
                helperText={otpError}
                id="otp"
                label="Otp"
                onChange={onChange}
                value={otp}
                required
            />
        </FormGroup>
    );
};

interface LoginFormGroupProps {
    form: LoginFormInterface;
    hideGlobalError?: boolean;
    onChange: any;
    otpDisable: boolean;
    sendOtp: any;
}

export default LoginFormGroup;
