// External Dependencies
import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from 'firebase/app';
import styles from 'styled-components';

// Internal Dependencies
import SurveyListTable from './SurveyListTable';

const Container = styles.div`
  margin: 0 1em;
  width:100%;
  & > h2 {
    font-family: Raleway;
  }
`;

var meta = document.createElement('meta');
meta.setAttribute('robots', 'noindex,nofollow');
document.getElementsByTagName('head')[0].appendChild(meta);

// Component Definition
const SurveyList = () => {
  const [items, setItems] = useState<Array<any>>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  
  const fetchSurveyList = () => {
    const db = firebase.firestore();
    db.collection('ExternalSurvey')
      .get()
      .then((querySnapshot:any) => {
        const newItems: Array<any> = [];
        querySnapshot.forEach((doc) => {
          newItems.push({ id: doc.id, ...doc.data() });
        });
        setItems(newItems);
        setIsFetching(false);
      })
      .catch((err) => {
        // TODO: Handle error case
      });
  };

  useEffect(fetchSurveyList, []);

  return (
    <Container>
      <h2>Survey List</h2>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <SurveyListTable onRefetch={fetchSurveyList} items={items} />
      )}
    </Container>
  );
};

export default SurveyList;
