// External Dependencies
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

// Internal Dependencies
import { FlexRow } from 'shared/styled-components';

/**
 * @callback onChange
 * @param {Object} event - The input event object describing the DOM element that was modified.
 *  We're only concerned with two attributes from the target element:
 *  id (identifies the textfield that was modified), and value (the new text value)
 */

/**
 * Displays a pair of textfields for the user's first and last name.
 * @param {Object} form - The data used for each textfield. Key names should match the ids used here.
 * @param {onChange} onChange - Callback function when any field is modified
 */
const NameFormGroup = ({
  form: { firstName, firstNameError, lastName, lastNameError },
  onChange = (_) => {}
}) => (
  <FlexRow>
    <NameField
      error={Boolean(firstNameError)}
      helperText={firstNameError}
      id="firstName"
      label="First Name"
      onChange={onChange}
      value={firstName}
      required
    />
    <NameField
      error={Boolean(lastNameError)}
      helperText={lastNameError}
      id="lastName"
      label="Last Name"
      onChange={onChange}
      value={lastName}
      required
    />
  </FlexRow>
);

// Component Styles
const NameField = styled(TextField)`
  &.MuiTextField-root {
    flex-grow: 1;
  }
  &.MuiTextField-root:first-child {
    margin-right: 1em;
  }
`;

export default NameFormGroup;
