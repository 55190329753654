// @ts-nocheck
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, {useContext} from 'react';
import styled from 'styled-components';
import Warning from '@material-ui/icons/Warning';
import { useParams } from 'react-router-dom';
// Internal Dependencies
import { SessionContext } from 'session/SessionContext';
import NewAccountForm from 'shared/forms/NewAccountForm';
import { RebateContext } from '../RebateContext';
import { ErrorText } from 'shared/styled-components';
import SurveyForm from './SurveyForm';

// Component Definition
const PaymentStep = ({ ...other }) => {
  const { brandName } = useParams<{ brandName: string }>();
  const { isGetting, user } = useContext(SessionContext);
  const {
    paymentForm: form,
    setPaymentForm: setForm,
    surveyForm,
    isSubmitting,
    setDeleteSurvey,
    deleteSurvey,
    isSurveyDeleteDialog,
    setIsSurveyDeleteDialog
  } = useContext(RebateContext);

  // Event handlers
  const handleChange = ({ target: { id, value } }: React.ChangeEvent<HTMLInputElement>): void =>
    setForm({ ...form, error: null, [`${id}Error`]: null, [id]: value });

  // const handlePhoneBlur = () =>
  //   setForm({ ...form, phoneNumber: formatPhoneNumber(form.phoneNumber) });

  let content;

  if (isGetting) {
    return (
      <div {...other}>
        <CircularProgress />
        <p>Getting user data...</p>
      </div>
    );
  }

  // User has already logged in
  if (user && user.paymentMethod) {
    const paymentMethodText =
      form.paymentMethod === 'venmo' ? (
        <span>
          Venmo{' '}
          <strong>
            {form.venmoUsername.startsWith('@') ? form.venmoUsername : '@' + form.venmoUsername}
          </strong>
        </span>
      ) : (
        `Cash by mail\n${form.primaryLine} ${form.secondaryLine} ${form.city}, ${form.state} ${form.zip}`
      );

    content = (
      <React.Fragment>
        <IconText>
          <CheckIcon style={{ alignSelf: 'center', marginRight: '.5em' }} />
          <p>
            You are logged in as <strong>{user.email}</strong>. Your payment information has been
            added below:
            <br />
            <br />
            {paymentMethodText}
          </p>
        </IconText>
        {!isSubmitting && surveyForm.length > 0 && !deleteSurvey && (
          <>
            <SurveyForm />
            {/* <div style={{ padding: 10 }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsSurveyDeleteDialog((prev) => !prev);
                }}
              >
                Delete Survey
              </Button>
            </div> */}
          </>
        )}
      </React.Fragment>
    );
  } else {
    content = (
      <>
        <NewAccountForm
          brandName={brandName}
          form={form}
          onChange={handleChange}
          setOtpResponse={other.setOtpResponse}
          onSubmit={() => {}}
          isSurvey={true}
          deleteSurvey={deleteSurvey}
          setDeleteSurvey={setDeleteSurvey}
        />
      </>
    );
  }

  // New user
  return (
    <div {...other}>
      {/* @ts-ignore */}
      {Boolean(form.error) && typeof form.error === 'string' && (
        <ErrorText>
          <Warning style={{ marginRight: 8, verticalAlign: 'sub' }} />
          {form.error}
        </ErrorText>
      )}
      {content}
    </div>
  );
};

// Component styles
const IconText = styled.div`
  background-color: #e8f5e9;
  border: solid 1px #004d40;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  margin-bottom: 2em;
  padding: 0 1em;
`;

export default PaymentStep;
