import {
  MenuItem,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import { FormGroupHeader } from 'shared/styled-components';
import styled from 'styled-components';
import { RebateContext } from 'rebates/RebateContext';
import firebase from 'firebase';

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const SurveyForm = (props) => {
  const {
    setSurveyError,
    setSurveyResult,
    surveyForm,
    setSurveyForm,
    isSubmitted,
    brandDetails,
    rebateSelectionForm: { selectedRebates }
  } = useContext(RebateContext);

  const { values, setValues, errors, touched, handleChange, handleBlur, setFieldValue } = useFormik(
    {
      initialValues: {
        surveyQuestions: isSubmitted
          ? surveyForm
          : surveyForm.map((result) => {
              return {
                title: result.title,
                questions:
                  result.questions?.length > 0
                    ? result.questions.map((result) => {
                        if (result.type === 'Multi-Select') {
                          return { ...result, answer: [] };
                        } else {
                          return { ...result, answer: '' };
                        }
                      })
                    : []
              };
            }) || []
      },
      enableReinitialize: true
    }
  );
  const getSurveyResult = async () => {
    try {
      const result = await firebase
        .firestore()
        .collection('SurveyResult')
        .doc(`${firebase.auth().currentUser.uid}${selectedRebates[0].name}`)
        .get();
      if (result.exists) {
        const foundAnswer = result.data().surveyResult;
        for (let [i, v] of values.surveyQuestions[0].questions.entries()) {
          const isAnswerPresent = foundAnswer.find((res) => res.question === v.question)?.answer;
          if (isAnswerPresent) {
            setFieldValue(`surveyQuestions[0].questions[${i}].answer`, isAnswerPresent);
          }
        }
      }
    } catch (err) {
      console.log('Error fetching survey result', err);
    }
  };

  useEffect(() => {
    if (firebase?.auth?.()?.currentUser?.uid) {
      getSurveyResult();
    }
  }, [firebase?.auth?.()?.currentUser?.uid]);
  const checkSurveyError = () => {
    const finalResult = [];

    if (Array.isArray(values.surveyQuestions)) {
      for (let survey of values.surveyQuestions) {
        for (let question of survey.questions) {
          if (question.required && !question.answer) {
            setSurveyError(true);
            return;
          }
          if (
            question.required &&
            question.type === 'Multi-Select' &&
            question.answer.length === 0
          ) {
            setSurveyError(true);
            return;
          }
        }
      }
      setSurveyError(false);
      for (let survey of values.surveyQuestions) {
        finalResult.push({
          title: survey.title,
          questions: survey.questions.map((question) => ({
            question: question.question,
            answer: question.answer
          }))
        });
      }
      setSurveyForm(values.surveyQuestions);
      setSurveyResult(finalResult);
    }
  };

  useEffect(() => {
    checkSurveyError();
  }, [values]);

  const handleMultiSelect = (event, surveyId, id) => {
    const {
      target: { value }
    } = event;
    setFieldValue(
      `surveyQuestions[${surveyId}].questions[${id}].answer`,
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div>
      {values.surveyQuestions.map((result, surveyIdx) => {
        return (
          <React.Fragment key={result.title}>
            <FormGroupHeader>Survey Questions</FormGroupHeader>
            {result?.questions?.map((question, index) => {
              switch (question.type) {
                case 'Open Ended':
                case 'Short Answer':
                  return (
                    <QuestionContainer key={question.question}>
                      <TextField
                        margin="dense"
                        name={`surveyQuestions[${surveyIdx}].questions[${index}].answer`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={question.required}
                        label={question.question}
                        value={question.answer}
                        placeholder={question.question}
                        error={
                          question.required
                            ? touched?.surveyQuestions?.[surveyIdx]?.questions?.[index]?.answer &&
                              !question.answer
                            : 'false'
                        }
                        helperText={
                          touched?.surveyQuestions?.[surveyIdx]?.questions?.[index]?.answer &&
                          question.required &&
                          !question.answer
                            ? 'Please fill in the answer'
                            : ''
                        }
                      />
                    </QuestionContainer>
                  );

                case 'Yes/No':
                  return (
                    <div key={question.question} style={{ textAlign: 'left', marginTop: 10 }}>
                      <FormLabel style={{ textAlign: 'left' }} required={question.required}>
                        {question.question}
                      </FormLabel>
                      <RadioGroup
                        row
                        name="customized-radios"
                        onChange={(e) =>
                          setFieldValue(
                            `surveyQuestions[${surveyIdx}].questions[${index}].answer`,
                            e.target.value
                          )
                        }
                        value={question.answer}
                      >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  );
                case 'Select':
                  return (
                    <QuestionContainer key={question.question}>
                      <TextField
                        margin="dense"
                        select
                        name={`surveyQuestions[${surveyIdx}].questions[${index}].answer`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={question.required}
                        value={question.answer}
                        label={question.question}
                        placeholder={question.question}
                        error={
                          question.required
                            ? touched?.surveyQuestions?.[surveyIdx]?.questions?.[index]?.answer &&
                              !question.answer
                            : false
                        }
                        helperText={
                          touched?.surveyQuestions?.[surveyIdx]?.questions?.[index]?.answer &&
                          question.required &&
                          !question.answer
                            ? 'Please select an option'
                            : ''
                        }
                      >
                        {question.options.map((option) => {
                          return (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </QuestionContainer>
                  );
                case 'Long Answer':
                  return (
                    <QuestionContainer key={question.question}>
                      <TextField
                        multiline
                        margin="dense"
                        minRows={1}
                        style={{ width: '100%' }}
                        name={`surveyQuestions[${surveyIdx}].questions[${index}].answer`}
                        value={question.answer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={question.required}
                        label={question.question}
                        placeholder={question.question}
                        error={
                          question.required
                            ? touched?.surveyQuestions?.[surveyIdx]?.questions?.[index]?.answer &&
                              !question.answer
                            : false
                        }
                        helperText={
                          touched?.surveyQuestions?.[surveyIdx]?.questions?.[index]?.answer &&
                          question.required &&
                          !question.answer
                            ? 'Please fill in the answer'
                            : ''
                        }
                      />
                    </QuestionContainer>
                  );
                case 'Multi-Select':
                  return (
                    <QuestionContainer key={question.question}>
                      {/* <InputLabel id="demo-simple-select-helper-label">Hello</InputLabel> */}
                      <TextField
                        margin="dense"
                        labelId="demo-simple-select-helper-label"
                        select
                        name={`surveyQuestions[${surveyIdx}].questions[${index}].answer`}
                        onChange={(e) => handleMultiSelect(e, surveyIdx, index)}
                        onBlur={handleBlur}
                        required={question.required}
                        value={question.answer}
                        SelectProps={{
                          multiple: true,
                          value: question.answer,
                          onChange: (e) => handleMultiSelect(e, surveyIdx, index)
                        }}
                        // input={<OutlinedInput label="Hello" />}
                        placeholder={question.question}
                        label={question.question}
                        error={
                          question.required
                            ? touched?.surveyQuestions?.[surveyIdx]?.questions?.[index]?.answer &&
                              question?.answer?.length === 0
                            : false
                        }
                        helperText={
                          question.required &&
                          touched?.surveyQuestions?.[surveyIdx]?.questions?.[index]?.answer &&
                          question?.answer?.length === 0
                            ? 'Please select an option'
                            : ''
                        }
                      >
                        {question.options.map((option) => {
                          return (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </QuestionContainer>
                  );
                default:
                //
              }
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default withRouter(SurveyForm);
