import { Button, CircularProgress, TextField } from '@material-ui/core';

const AddCompanyForm = (props) => {
  const { handleChange, handleBlur, touched, errors, values, isSubmitting, addCompany } = props;
  return (
    <form onSubmit={() => 0}>
      <div>
        <TextField
          margin="dense"
          variant="outlined"
          placeholder="Enter company name"
          label="Company Name"
          id="company1"
          value={values.company1}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.company1 ? errors.company1 : ''}
          error={touched.company1 && Boolean(errors.company1)}
          required
          fullWidth
        ></TextField>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button onClick={addCompany} variant="outlined" color="primary">
          {isSubmitting ? <CircularProgress size={20} /> : 'SAVE'}
        </Button>
      </div>
    </form>
  );
};

export default AddCompanyForm;
