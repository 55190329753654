import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormLabel,
  CircularProgress
} from '@material-ui/core';
import { useState } from 'react';
import SafeButton from 'shared/SafeButton';
import { emailRegex } from 'shared/validation';
import firebase from 'firebase';

const ForgotPasswordDialog = ({ onClose, ...dialogProps }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isResetEmailSent, setIsResetEmailSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    if (emailRegex.test(email)) {
      setIsSubmitting(true);
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
        })
        .catch((error) => {
        })
        .finally(() => {
          setEmail('');
          setIsSubmitting(false);
          setIsResetEmailSent(true);
        });
    } else {
      setError('Enter your valid email');
    }
  };
  return (
    <Dialog maxWidth="xs" onClose={onClose} {...dialogProps}>
      <DialogTitle>Forgot Password?</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <FormLabel style={{ textAlign: 'center' }}>
            Enter the email associated with your account and we will send an email to reset your
            password.
          </FormLabel>
          <TextField
            onChange={(e) => {
              if (isResetEmailSent) {
                setIsResetEmailSent(false);
              }
              setEmail(e.target.value);
              if (emailRegex.test(e.target.value)) {
                setError('');
              } else {
                setError('Enter valid email');
              }
            }}
            value={email}
            placeholder="Enter your email address"
            type="email"
            required
            error={Boolean(error)}
            helperText={error && 'Enter valid email'}
          />
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        {isSubmitting ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <SafeButton
            style={{ textAlign: 'center' }}
            disabled={error}
            fullWidth
            onClick={handleSubmit}
            variant="outlined"
          >
            EMAIL ME A PASSWORD RESET LINK
          </SafeButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
