// External Dependencies
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import SafeButton from 'shared/SafeButton';
import { createOrUpdateUser } from 'shared/api';
import { validateUserInfo } from 'shared/validation';
import { ErrorText, FlexRow } from 'shared/styled-components';
import { GroupHeader, DataHeader, DataValue } from './profile-styled-components';
import NameFormGroup from 'shared/forms/NameFormGroup';
import { TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import { SessionContext } from 'session/SessionContext';
import { useHistory } from 'react-router-dom';

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fdfff0;
  border: solid 1px #bdbdbd;
  border-radius: 2px;
  padding: 1em;
  margin-top: 1em;
`;

const PasswordToggleText = styled.span`
  color: #26a69a;
  cursor: pointer;
  margin-left: 0.75em;
`;

// const PasswordResetLink = ({ user }) => {
//   const [isResetEmailSent, setIsResetEmailSent] = useState<boolean>(false);
//   const resetPassword = () => {
//     firebase.auth().sendPasswordResetEmail(user.email)
//       .then(() => setIsResetEmailSent(true))
//       .catch(error => console.log('Error sending password email reset to ', user?.email));
//   };
//   const content = isResetEmailSent
//     ? 'Password reset! Please check your email'
//     : 'Click here to reset your password';
//   return (
//     <span onClick={resetPassword}>{content}</span>
//   );
// }

// Component Definition
const UserInfoSettings = ({ onUpdated, userData }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  // const [showEmailNotice, setShowEmailNotice] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [form, setForm] = useState(userData);

  const handleChange = ({ target: { id, value } }: React.ChangeEvent<HTMLInputElement>): void =>
    setForm({ ...form, error: null, [`${id}Error`]: null, [id]: value });

  const { email, error, isSubmitting } = form;

  const { reloadUser } = useContext(SessionContext);

  const history = useHistory();

  const updateUserInfo = async () => {
    if (isSubmitting) {
      return;
    }
    setForm((prev) => ({ ...prev, isSubmitting: true }));

    const newForm = validateUserInfo({ ...form, changeInfo: true });

    if (Boolean(newForm.error)) {
      setForm(newForm);
      return;
    }

    const fbUser: any = firebase.auth().currentUser;
    const emailChanged = userData.email !== email ? true : false;
    // const updateEmailPromise = userData.email !== email ? fbUser?.updateEmail(email) : null;
    // const updateUserDataPromise = createOrUpdateUser(fbUser?.uid, form);
    // const updates = [updateEmailPromise, updateUserDataPromise];

    try {
      if (emailChanged) {
        await fbUser.updateEmail(email);
        await createOrUpdateUser(fbUser?.uid, form);
      } else {
        await createOrUpdateUser(fbUser?.uid, form);
      }
      setForm({ ...form, isSubmitting: false });
      setIsEditMode(false);
      onUpdated();
      toast.success('Userinfo updated successfully',{position: "top-right"});
      reloadUser();
      if (emailChanged) {
        // setShowEmailNotice(true);
        await fbUser?.sendEmailVerification();
        toast.info('Please verify your new email',{position: "top-right"});
        // await fbUser?.signOut();
        // history.push('/');
      }
    } catch ({ message }) {
      setForm({ ...form, error: message, isSubmitting: false });
    }
  };

  const displayContent = (
    <div>
      <FlexRow>
        <div>
          <DataHeader>First Name</DataHeader>
          <DataValue>{userData.firstName}</DataValue>
        </div>
        <div style={{ marginLeft: '2em' }}>
          <DataHeader>LastName</DataHeader>
          <DataValue>{userData.lastName}</DataValue>
        </div>
      </FlexRow>
      <DataHeader>Email</DataHeader>
      <DataValue>{email}</DataValue>
    </div>
  );

  const editContent = (
    <EditContainer>
      {Boolean(error) && typeof error === 'string' ? <ErrorText>{error}</ErrorText> : null}
      <NameFormGroup form={form} onChange={handleChange} />
      <TextField
        error={Boolean(form.emailError)}
        fullWidth
        helperText={form.emailError}
        id="email"
        label="Email"
        onChange={handleChange}
        value={form.email}
        required
      />
      {/* <LoginFormGroup form={form} hideGlobalError onChange={handleChange} /> */}
      <SafeButton onClick={updateUserInfo} isSubmitting={isSubmitting} variant="contained">
        Save Changes
      </SafeButton>
    </EditContainer>
  );

  return (
    <div>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <GroupHeader style={{ alignSelf: 'center' }}>User Information</GroupHeader>
        <Button onClick={() => setIsEditMode(!isEditMode)} size="small" variant="outlined">
          {isEditMode ? 'Cancel' : 'Edit'}
        </Button>
      </FlexRow>
      {isEditMode ? editContent : displayContent}
    </div>
  );
};

export default UserInfoSettings;
