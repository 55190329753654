import React from "react";

const MainHeader = ({ text, top, bottom }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: top ? top : null,
        marginBottom: bottom ? bottom : null,
      }}
    >
      <h1 style={{ fontSize: "42px" }}>{text}</h1>
    </div>
  );
};

export default MainHeader;
