// External Dependencies
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';

// A collection of common UI components with no functionality

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormGroupHeader = styled.h3`
  color: #757575;
  font-weight: 500;
  font-size: 2em;
  margin: 1em 0em 0em 0em;
`;

export const ErrorText = styled.p`
  background-color: #fff8e1;
  border: solid 1px #f44336;
  border-radius: 2px;
  color: #f44336;
  padding: .5em;
`;

export const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  color: -webkit-link;
  cursor: pointer;
  font-size: inherit;
  padding: 0;
  text-decoration: underline;
`;

export const PageContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  margin: 0 auto;
  overflow-y: auto;
  padding: 0 1em;
  text-align: center;
  width: 100%;

  @media (min-width: 726px) {
    width: 64em;
  }
`;

export const BrandPageContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  margin: 0 auto;
  overflow-y: auto;
  padding: 0 1em;
  text-align: center;
  width: 100%;

  @media (min-width: 726px) {
    width: 95%;
  }
`;

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: 0.5em 0 1.5em 0;
  }
`;
