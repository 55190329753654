// @ts-nocheck
// External Dependencies
import firebase from "firebase/app";
import "firebase/firestore";
import {values} from "lodash";
import {toast} from "react-toastify";

export const getFirestore = async () => {
    return await firebase.firestore();
}

export const getCollectionId = async (collectionName) => {
    return await firebase.firestore().collection(collectionName).doc().id;
}

// Upload user data, creates new object if it doesn't exist
export const createOrUpdateUser = (id, newUserData) =>
    firebase.firestore()
        .collection('Users')
        .doc(id)
        .set({...newUserData}, {merge: true});

export const createNewUser = (userData) => {
    const {
        email,
        password//,
        // phoneNumber,
        // isPromotionsEmailChecked,
        // isPromotionsTextChecked
    } = userData;
    return firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(({user}) => {
            if (!user) {
                throw new Error('Firebase created a new user without returning any credentials. User data was not saved to firestore.');
            }

            // TODO autosubscribe
            delete userData.password;
            return createOrUpdateUser(user?.uid, userData)
                .then(() => user.sendEmailVerification());
        })
        .catch(err => {
            if (err.code === 'auth/email-already-in-use') {
                err.message = "The email address is already in use. Please login or enter a different email address.";
            }
            const {message} = err;
            // const { code, message } = err;
            // if (code === 'auth/weak-password') {
            //   error = 'Not account found with that username and password'
            // }
            throw new Error(message);
        });
};

export const getUserByPhone = (phoneNumber) => {
    const db = firebase.firestore();
    return db.collection('Users')
        .where('phoneNumber', '==', phoneNumber)
        .get()
        .then((querySnapshot) => {
            const data: Array<any> = [];
            querySnapshot.forEach((doc) => {
                data.push({id: doc.id, ...doc.data()});
            });
            return data;
        })
        .catch((error) => {
            throw error;
        });
};

// Get eligible rebates for a specific brand (case sensitive)
export const getRebatesByBrand = (brandName) => {
    const db = firebase.firestore();
    return db.collection('Rebates')
        .where('brandName', '==', brandName)
        .get()
        .then((querySnapshot) => {
            const data: Array<any> = [];
            querySnapshot.forEach((doc) => {
                data.push({id: doc.id, ...doc.data()});
            });
            return data;
        })
        .catch((error) => {
            throw error;
        });
};

export const getRebatesByBrandId = (brandId) => {
    const db = firebase.firestore();
    return db.collection('Brand')
        .where('id', '==', brandId)
        .get()
        .then((querySnapshot) => {
            const data: Array<any> = [];
            querySnapshot.forEach((doc) => {
                data.push({id: doc.id, ...doc.data()});
            });
            return data;
        })
        .catch((error) => {
            throw error;
        });
};

export const getRebatesByIds = (ids) => {
    const db = firebase.firestore();
    return db.collection('Rebates')
        .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
        .get()
        .then((querySnapshot) => {
            const data: Array<any> = [];

            // TODO: Handle when querySnapshot.size == 0

            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });

            return data;
        })
        .catch((error) => {
            throw error;
        });
};

export const uploadImageToFirestore = (imageFile, fileName, storageName = 'rebate_receipts') => {
    // Upload file to firebase storage
    const storage = firebase.storage();
    const bucket = storage.ref().child(storageName);

    // Begin upload and forward image location when done
    return bucket
        .child(fileName)
        .put(imageFile)
        .then(snapshot => snapshot.ref.getDownloadURL())
        .then(downloadUrl => downloadUrl)
        .catch((error) => {
                throw error;
            }
        );
};

export const deleteFileFromFirestore = (fileName, storageName = 'rebate_receipts') => {
    // Upload file to firebase storage
    const storage = firebase.storage();
    const bucket = storage.ref().child(storageName);

    // Begin upload and forward image location when done
    return bucket
        .child(fileName)
        .delete()
        .then(() => console.log("Files deleted successfully"))
        .catch((error) => {

                throw error;
            }
        );
};

export const postRebateClaiming = ({
                                       brandName,
                                       email,
                                       imageUrl,
                                       selectedRebates,
                                       userId,
                                       venmoUsername,
                                       phoneNumber,
                                       surveyResult = [],
                                       refund = 0
                                   }) =>
    firebase.firestore()
        .collection('RebateClaimings')
        .add({
            brandName,
            brandRef: 'temp',
            dateCreated: (new Date()).toISOString(),
            email,
            rebateIDs: selectedRebates,
            receiptUrl: imageUrl,
            refund: refund,
            status: 'Pending',
            userId,
            venmoUsername,
            phoneNumber,
            surveyResult
        })
        .catch((error) => {
                throw error;
            }
        );

export const getRebateClaiming = id =>
    firebase.firestore()
        .collection('RebateClaimings')
        .doc(id)
        .get()
        .catch((error) => {
                throw error;
            }
        );

export const postEmailList = (email, brandName) =>
    firebase.firestore()
        .collection('EmailList')
        .doc(email)
        .set({
            accountId: firebase.auth().currentUser?.uid,
            brands: firebase.firestore.FieldValue.arrayUnion(brandName),
        })
        .catch((error) => {
                throw error;
            }
        );

export const deleteEmailList = (email, brandName) =>
    firebase.firestore()
        .collection('EmailList')
        .doc(email)
        .get()
        .then(doc => {
            if (!doc.exists) {
                console.error(`${email} was not in our email list to begin with`)
                return;
            }

            const {brands} = doc.data();
            if (brandName === 'calitopia' || brands.length <= 1) {
                return doc.ref.delete()
            }

            return doc.ref.set({
                accountId: firebase.auth().currentUser?.uid,
                brands: firebase.firestore.FieldValue.arrayRemove(brandName),
            })
        })
        .catch((error) => {
                throw error;
            }
        );

export const postTextList = (phoneNumber, brandName) =>
    firebase.firestore()
        .collection('TextList')
        .doc(phoneNumber)
        .set({
            accountId: firebase.auth().currentUser?.uid,
            brands: firebase.firestore.FieldValue.arrayUnion(brandName),
        })
        .catch((error) => {
                throw error;
            }
        );

export const deleteTextList = (phoneNumber, brandName) =>
    firebase.firestore()
        .collection('TextList')
        .doc(phoneNumber)
        .get()
        .then(doc => {
            if (!doc.exists) {
                console.error(`${phoneNumber} was not in our text list to begin with`)
                return;
            }

            const {brands} = doc.data();
            if (brandName === 'calitopia' || brands.length <= 1) {
                return doc.ref.delete()
            }

            return doc.ref.set({
                accountId: firebase.auth().currentUser?.uid,
                brands: firebase.firestore.FieldValue.arrayRemove(brandName),
            })
        })
        .catch((error) => {
                throw error;
            }
        );

export const postCompany = async (name) => {
    try {
        const checkCompany = await firebase.firestore().collection('Company').where("name", "==", name).get();
        if (checkCompany.docs.length > 0) {
            throw new Error("Company name already exists");
        }
        await firebase.firestore().collection('Company').add({name});
    } catch (error) {
        console.log(error);
        throw error.message;
    }
}

export const fetchCompany = async () => {

    try {
        const result = await firebase.firestore().collection('Company').get();
        return result.docs.map((result) => ({...result.data(), id: result.id}))
    } catch (error) {
        console.error(error);
        throw error.message;
    }
}

export const postBrand = async ({values, create = false}) => {
    try {
        const {name, company, id} = values;
        if (!id) {
            const checkCompany = await firebase.firestore().collection('Brand').where("name", "==", name).where("company", "==", company).get();
            if (checkCompany.docs.length > 0) {
                throw new Error("Brand  name already exists");
            }
            return await firebase.firestore().collection('Brand').add(values);
        } else {
            await firebase.firestore().collection('Brand').doc(id).update(values);
            return await firebase.firestore().collection('Brand').doc(id).get();
        }
    } catch (error) {
        console.log(error);
        throw error.message;
    }
}


export const getParticipatingStoresByStoreId = async ({couponId, storeId}) => {
    try {
        const result = await firebase.firestore().collection('ParticipatingStore').where("storeId", "==", storeId).get();
        if (result.exists) {
            return {
                id: result.id, ...result.data()
            };
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
    }
}

export const deleteParticipatingStores = async (id) => {
    try {
        await firebase.firestore().collection('ParticipatingStores').doc(id).delete();
        console.log(`ParticipatingStore with ${id} deleted successfully`);
    } catch (error) {
        console.error(`Issue while deleting participating stores. Error: ${error}`);
    }
}
export const postParticipatingStores = async (data) => {
    const {couponId, storeId} = data;
    await firebase.firestore().collection('ParticipatingStores').doc(`${storeId}-${couponId}`).set(data, {merge: true});
}

export const postRebate = async (data) => {
    console.log("Post rebate", data);
    const {id} = data;
    await firebase.firestore().collection('Rebate').doc(id).set(data, {merge: true});
}

export const postCoupon = async (data) => {
    console.log("Post coupon", data);
    const {id} = data;
    await firebase.firestore().collection('Coupon').doc(id).set(data, {merge: true});
}

export const deleteRebateFromDB = async (id) => {
    await firebase.firestore().collection('Rebate').doc(id).delete()
}

export const deleteCouponFromDB = async (id) => {
    try {
        await firebase.firestore().collection('Coupon').doc(id).delete();
        console.log(`Coupon ${id} deleted successfully`);
    } catch (err) {
        console.log(`Error while deleting coupon ${id} Error: ${err}`);
    }
}


export const postStore = async ({values, create = false}) => {
    try {
        const {name} = values;
        if (!values.id) {
            const checkStore = await firebase.firestore().collection('Store').where("name", "==", name).get();
            if (checkStore.docs.length > 0) {
                throw new Error("Store name already exists");
            }
            const result = await firebase.firestore().collection('Store').add(values);
            return result;
        } else {
            await firebase.firestore().collection('Store').doc(values.id).update(values);
            const result = await firebase.firestore().collection('Store').doc(values.id).get();
            return result;
        }
    } catch (error) {
        console.log(error);
        throw error.message;
    }
}


export const fetchBrand = async (company) => {
    try {
        const result = await firebase.firestore().collection('Brand').where("company", "==", company).get();

        return result.docs.map((result) => {
            if (result.data()?.coupons) {
                return {
                    ...result.data(), id: result.id,
                }
            } else {
                return {
                    ...result.data(), id: result.id, coupons: [], couponList: []
                }
            }

        })
    } catch (error) {
        console.error(error);
        throw error.message;
    }
}


export const fetchStores = async (company) => {
    try {
        const result = await firebase.firestore().collection('Store').get();

        return result.docs.map((result) => {

            return {
                ...result.data(), id: result.id,
            }
        })
    } catch (error) {
        console.error(error);
        throw error.message;
    }
}

export const updatePassword = async ({user, currentPassword, newPassword}) => {
    try {
        try {
            await firebase.auth().signInWithEmailAndPassword(user.email, currentPassword);
        } catch (error) {
            throw new Error("Current Password didnot match");
        }
        try {
            await user.updatePassword(newPassword);
            await firebase.auth().signInWithEmailAndPassword(user.email, newPassword);
        } catch (error) {
            throw new Error("Issue while updating password")
        }
        return toast.success("Password Changed Successfully", {position: "top-right"})
    } catch (error) {
        toast.error(error.message, {position: "top-right"})
        throw error;
    }
}

export const getRebateDetailsByTitle = async (rebateName) => {
    try {
        // const getRebates = await firebase.firestore().collection('Rebate').where('name', 'in', [rebateName, rebateName.toLowerCase()]).get();
        // if (getRebates.docs.length > 0) {
        //   const rebateDetails = getRebates.docs[0].data();
        //   const { brandId } = rebateDetails;
        //   const result = await firebase.firestore().collection('Brand').doc(brandId).get();
        //   return { ...result.data(), rebates: rebateDetails, }
        // }
        // else {
        //   throw new Error("Rebate not found")
        // }
        const result = await firebase.firestore().collection('Brand').where('rebateList', 'array-contains-any', [rebateName, rebateName.toLowerCase()]).get();
        console.log("Rebate", result.docs.length);
        if (result.docs.length === 1) {
            const data = result.docs[0].data();
            return {
                ...data,
                rebates: data.rebates.find((rebate) => rebate.name.toLowerCase() === rebateName.toLowerCase())
            }
        } else {
            throw new Error("Rebate not found")
        }
    } catch (err) {
        console.log("Rebate", err);
        throw err;
    }
}

export const getBrandBySlug = async (brandName) => {
    try {
        const result = await firebase
            .firestore()
            .collection('Brand')
            .where('slugs', 'array-contains-any', [brandName, brandName.toLowerCase()])
            .where('live', '==', true)
            .get();
        if (result.docs.length > 0) {
            const data = result.docs[0].data();
            return data;
        } else {
            throw new Error("Brand not found");
        }
    } catch (err) {
        throw err;
    }
};

export const getAllCategories = async () => {
    try {
        const result = await firebase.firestore().collection('Categories').get();
        return result.docs.map((res) => res.data());
    } catch (err) {
        throw err;
    }
}

export const getAllBrands = async (live = true) => {
    try {
        const result = live ? await firebase
            .firestore()
            .collection('Brand')
            .where('live', '==', true)
            .get() : await firebase
            .firestore()
            .collection('Brand')
            .get();
        if (result.docs.length > 0) {
            return result.docs.map((res) => res.data());
        } else {
            return []
        }
    } catch (err) {
        throw err;
    }
};

export const getSurveyDetails = async (surveyId) => {
    try {
        const result = await firebase.firestore().collection('ExternalSurvey')
            .where('surveyId', '==', surveyId)
            .get();
        return result.docs.map((result) => {
            if (result.data()) {
                return {...result.data(), id: result.id,}
            } else {
                return null;
            }
        })
    } catch (err) {
        throw err;
    }
}

export const createOrUpdateSurvey = (data) =>
    firebase.firestore()
        .collection('ExternalSurvey')
        .doc(data.surveyId)
        .set({...data, dateCreated: (new Date()).toISOString()}, {merge: true});

export const linkSurveyWithBrandRebates = (data) =>
    firebase.firestore()
        .collection('LinkedExternalSurvey')
        .doc(data.brand)
        .set({...data, dateCreated: (new Date()).toISOString()}, {merge: true});

export const getAllSurvey = async () => {
    try {
        const result = await firebase.firestore().collection('ExternalSurvey').get();
        if (result.docs.length > 0) {
            return result.docs.map((res) => res.data());
        } else {
            return []
        }
    } catch (e) {
        throw e;
    }
}

export const getLinkedSurveyDetails = async (brandId) => {
    try {
        const result = await firebase.firestore().collection('LinkedExternalSurvey')
            .where('brand', '==', brandId)
            .get();
        return result.docs.map((result) => {
            if (result.data()) {
                return {...result.data(), id: result.id,}
            } else {
                return null;
            }
        })
    } catch (err) {
        throw err;
    }
}
