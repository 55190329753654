import { Button, TextField } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';

const LinkForm = (props) => {
  const { handleChange, handleBlur, touched, errors, result, index, deleteLink } = props;
  return (
    <div className="link">
      <TextField
        fullWidth
        margin="dense"
        variant="outlined"
        placeholder="Enter title*"
        id={`links[${index}].title`}
        label="Title"
        value={result.title}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.links?.[index]?.title ? errors.links?.[index]?.title : ''}
        error={touched.links?.[index]?.title && Boolean(errors.links?.[index]?.title)}
        required
      ></TextField>
      <TextField
        margin="dense"
        variant="outlined"
        placeholder="Enter url*"
        id={`links[${index}].url`}
        label="URL"
        value={result.url}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        helperText={touched.links?.[index]?.url ? errors.links?.[index]?.url : ''}
        error={touched.links?.[index]?.url && Boolean(errors.links?.[index]?.url)}
        required
      ></TextField>

      <Button variant="text" onClick={() => deleteLink(index)}>
        <DeleteOutline color="error" />
      </Button>
    </div>
  );
};

export default LinkForm;
