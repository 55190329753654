import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import styled from 'styled-components';

const SurveyResultContainer = styled.ol`
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  list-style-type: decimal;
  list-style-position: inside;
  & > li::marker {
    font-weight: bold;
  }
`;

const SurveyResult = styled.li`
  display: list-item;
  padding: 5px 5px 5px 0px;
  label:nth-child(1) {
    display: inline-block;
    font-weight: bold;
    padding: 5px;
  }
`;

const SurveyResultDialog = (props) => {
  const { surveyResult, show, setShow } = props;

  return (
    <Dialog maxWidth={'lg'} open={show} onClose={() => setShow(false)}>
      <DialogTitle style={{ borderBottom: '2px solid #eee' }}>Survey Result</DialogTitle>
      <DialogContent style={{ padding: 20 }}>
        {surveyResult.map((survey) => {
          return (
            <div key={survey.title}>
              <h3>
                Title: <label style={{ textDecoration: 'underline' }}>{survey.title}</label>
              </h3>
              <SurveyResultContainer>
                {survey.questions.map((result) => {
                  return (
                    <SurveyResult key={`${survey.title}-${result.key}`}>
                      <label>{result.question}</label>
                      <br />
                      <label style={{ textIndent: 22, display: 'inline-block' }}>
                        {Array.isArray(result.answer)
                          ? result.answer.join(',')
                          : result.answer || 'N.A.'}
                      </label>
                    </SurveyResult>
                  );
                })}
              </SurveyResultContainer>
            </div>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

export default SurveyResultDialog;
