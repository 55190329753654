// External Dependencies
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useCallback, useContext, useState } from 'react';

// Internal Dependencies
import { SessionContext } from 'session/SessionContext';
import { StyledDivider } from 'shared/styled-components';
import AgreementFormGroup from 'shared/forms/AgreementFormGroup';
import PaymentSettings from './PaymentSettings';
import UserInfoSettings from './UserInfoSettings';
import ChangePasswordSetting from './ChangePasswordSetting';

const LoadingContent = () => (
  <div>
    <p>Getting your profile</p>
    <CircularProgress />
  </div>
);

// Component Definition
const ProfileDialog = ({ onClose, ...dialogProps }) => {
  const { isGetting, reloadUser, user } = useContext(SessionContext);

  const reset = useCallback(() => ({
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    isPromotionsEmailChecked: user?.isPromotionsEmailChecked,
    isPromotionsTextChecked: user?.isPromotionsTextChecked
  }), [user]);

  const [form, setForm] = useState(reset());

  const handleChange = ({ target: { id, value } }: React.ChangeEvent<HTMLInputElement>): void =>
    setForm({ ...form, [id]: value }); //, error: null, [`${id}Error`]: null, [id]: value });

  React.useEffect(() => {
    setForm(reset());
  }, [reset, user]);

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
  //   setForm({ ...form, [e.target.id]: e.target.value, [`${e.target.id}Error`]: null, error: null });

  const handleSubmit = () => reloadUser().then(() => onClose());

  const successContent = (
    <div>
      <UserInfoSettings userData={user} onUpdated={() => reloadUser()} />
      {/*<StyledDivider />*/}

      {/*<ChangePasswordSetting userData={user} onUpdated={() => reloadUser()} />*/}
      <StyledDivider />

      <PaymentSettings userData={user} onUpdated={() => reloadUser()} />
      {/* <StyledDivider />

      <AgreementFormGroup form={form} onChange={handleChange} emailListOnly /> */}
    </div>
  );

  return (
    // @ts-ignore
    <Dialog onClose={handleSubmit} {...dialogProps} maxWidth="sm" fullWidth>
      <DialogTitle>My Profile</DialogTitle>
      <DialogContent>{isGetting ? <LoadingContent /> : successContent}</DialogContent>
    </Dialog>
  );
};

export default ProfileDialog;
