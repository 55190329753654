import React, {useContext, useEffect, useState} from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from "@mui/material/Typography";
import {useParams, useHistory} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {TextField, Radio, Button, Grid, Box, Select, InputLabel, MenuItem, FormHelperText} from "@mui/material";
import {defaultStepPaymentForm, PaymentForm, validatePaymentDetailsStep} from "../shared/validation";
import {ErrorText} from "../shared/styled-components";
import styled from "styled-components";
import {SessionContext} from "../session/SessionContext";
import {createOrUpdateUser, postRebateClaiming, uploadImageToFirestore} from "../shared/api";
import {toast} from "react-toastify";
import firebase from "firebase";

export default function PaymentStep({setStep, brandDetails, rebateSelectionForm, rebateDetails}) {
    const [form, setForm] = useState<PaymentForm>(defaultStepPaymentForm);
    const {user, userId, reloadUser} = useContext(SessionContext);
    const [imageFile, setImageFile] = useState<any>();

    const VENMO = 'venmo';
    const CASH_BY_MAIL = 'cash';
    const PAYPAL = 'paypal';
    const CASHAPP = 'cashApp';
    const history = useHistory();

    const handleChange = (e: { target: { id: string; value: any } }): any =>
        setForm({...form, [e.target.id]: e.target.value, [`${e.target.id}Error`]: null, error: null});

    const createFileName = (rebateName, type) => {
        const ext = type.split('/')[1];

        // Generate unique id
        return `${rebateName}_${firebase.auth().currentUser?.uid}_${uuidv4()}.${ext}`;
    };

    const handleRegister = () => {

        setForm({...form, isSubmitting: true});

        if (form.isSubmitting) {
            return;
        }
        const newForm = validatePaymentDetailsStep(form);
        if (Boolean(newForm.error)) {
            // @ts-ignore
            setForm({...newForm, isSubmitting: false})
            return;
        }
        if (!imageFile) {
            return toast.error('Please upload receipt.', {position: "top-right"})
        }
        return createOrUpdateUser(userId, newForm).then(async () => {
            return uploadImageToFirestore(imageFile, createFileName(brandDetails.rebates.name, imageFile.type))
                .then((downloadUrl) => {
                    if (!user) {
                        console.log('User data not ready');
                        throw new Error('User data not ready');
                    }
                    return postRebateClaiming({
                        brandName: brandDetails.name,
                        email: user.email,
                        imageUrl: downloadUrl,
                        selectedRebates: rebateSelectionForm.selectedRebates,
                        userId: firebase.auth().currentUser!.uid,
                        venmoUsername: form.venmoUsername,
                        phoneNumber: user.phoneNumber,
                        refund: rebateDetails
                            // @ts-ignore
                            .filter(({title}) => rebateSelectionForm.selectedRebates?.includes(title))
                            .reduce((acc, result: any) => acc + result.discount, 0)
                    });
                }).then((docRef) => history.push(`/rebate/status/${docRef.id}`, {isNew: true}))
                .catch((e) => {
                    window.scrollTo(0, 0);
                    console.log(e)
                    return toast.error('Error submitting rebate. Please try again later', {position: "top-right"})
                });
        })
    }

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files) {
            setImageFile(e.target.files[0]);
        } else {
            console.log('Unexpected error selecting file');
        }
    };

    return (
        <>
            <Box sx={{mt: 3, mb: 3}}>
                <Typography className="page-label" variant="h4" align="center" color="rgb(0, 160, 176)"
                            fontWeight="600">
                    Payment Details
                </Typography>
            </Box>
            <div className="payment-info-main">
                <Grid container spacing={1}>
                    {Boolean(form.error) && typeof form.error === 'string' ? <ErrorText>{form.error}</ErrorText> : null}
                    <Grid item xs={12} sm={12}>
                        <p style={{margin: '1.5em 0 0 0', marginBottom: '1.5em', fontSize: '1.1em', textAlign: 'left'}}>
                            How would you like to receive payments?
                        </p>
                        <FormControl>
                            <RadioGroup onChange={handleChange} row>
                                <FormControlLabel
                                    control={<Radio id="paymentMethod" checked={form.paymentMethod === VENMO}/>}
                                    label="Venmo"
                                    value={VENMO}
                                />
                                <FormControlLabel
                                    control={<Radio id="paymentMethod" checked={form.paymentMethod === CASH_BY_MAIL}/>}
                                    label="Cash By Mail"
                                    value={CASH_BY_MAIL}
                                />
                                <FormControlLabel
                                    control={<Radio id="paymentMethod" checked={form.paymentMethod === PAYPAL}/>}
                                    label="Paypal"
                                    value={PAYPAL}
                                />
                                <FormControlLabel
                                    control={<Radio id="paymentMethod" checked={form.paymentMethod === CASHAPP}/>}
                                    label="Cash App"
                                    value={CASHAPP}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {form.paymentMethod === VENMO && <Grid item xs={12} sm={12}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            label="Venmo Handle"
                            name="venmoHandle"
                            placeholder="Venmo Handle"
                            error={Boolean(form.venmoUsernameError)}
                            helperText={form.venmoUsernameError}
                            id="venmoUsername"
                            onChange={handleChange}
                            value={form.venmoUsername}
                        />
                    </Grid>}

                    {form.paymentMethod === PAYPAL && <Grid item xs={12} sm={12}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            id="paypalEmail"
                            label="Paypal Email"
                            name="paypalEmail"
                            placeholder="Paypal Email"
                            error={Boolean(form.paypalEmailError)}
                            helperText={form.paypalEmailError}
                            onChange={handleChange}
                            value={form.paypalEmail}
                        />
                    </Grid>}

                    {form.paymentMethod === CASHAPP && <Grid item xs={12} sm={12}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            id="cashAppUsername"
                            label="Cash App Username"
                            name="cashAppUsername"
                            placeholder="Cash App Username"
                            error={Boolean(form.cashAppUsernameError)}
                            helperText={form.cashAppUsernameError}
                            onChange={handleChange}
                            value={form.cashAppUsername}
                        />
                    </Grid>}

                    {form.paymentMethod === CASH_BY_MAIL && <>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                variant="standard"
                                margin="normal"
                                fullWidth
                                label="Address"
                                placeholder="Address"
                                error={Boolean(form.primaryLineError)}
                                helperText={form.primaryLineError}
                                id="primaryLine"
                                onChange={handleChange}
                                value={form.primaryLine}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                variant="standard"
                                margin="normal"
                                fullWidth
                                label="Unit. Apt. Ste. number"
                                name="unit_number"
                                placeholder="Unit. Apt. Ste. number"
                                error={Boolean(form.secondaryLineError)}
                                helperText={form.secondaryLineError}
                                id="secondaryLine"
                                onChange={handleChange}
                                value={form.secondaryLine}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                variant="standard"
                                margin="normal"
                                fullWidth
                                id="city"
                                label="City"
                                name="city"
                                placeholder="City"
                                error={Boolean(form.cityError)}
                                helperText={form.cityError}
                                onChange={handleChange}
                                value={form.city}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* @ts-ignore */}
                            <FormControl margin="normal" variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                <Select variant="standard" id="state" label="State"
                                        value={form.state}
                                        error={Boolean(form.stateError)}
                                        onChange={e => handleChange({target: {id: 'state', value: e.target.value}})}
                                >
                                    <MenuItem value="AL">AL</MenuItem>
                                    <MenuItem value="AK">AK</MenuItem>
                                    <MenuItem value="AS">AS</MenuItem>
                                    <MenuItem value="AZ">AZ</MenuItem>
                                    <MenuItem value="AR">AR</MenuItem>
                                    <MenuItem value="CA">CA</MenuItem>
                                    <MenuItem value="CO">CO</MenuItem>
                                    <MenuItem value="CT">CT</MenuItem>
                                    <MenuItem value="DE">DE</MenuItem>
                                    <MenuItem value="DC">DC</MenuItem>
                                    <MenuItem value="FM">FM</MenuItem>
                                    <MenuItem value="FL">FL</MenuItem>
                                    <MenuItem value="GA">GA</MenuItem>
                                    <MenuItem value="GU">GU</MenuItem>
                                    <MenuItem value="HI">HI</MenuItem>
                                    <MenuItem value="ID">ID</MenuItem>
                                    <MenuItem value="IL">IL</MenuItem>
                                    <MenuItem value="IN">IN</MenuItem>
                                    <MenuItem value="IA">IA</MenuItem>
                                    <MenuItem value="KS">KS</MenuItem>
                                    <MenuItem value="KY">KY</MenuItem>
                                    <MenuItem value="LA">LA</MenuItem>
                                    <MenuItem value="ME">ME</MenuItem>
                                    <MenuItem value="MH">MH</MenuItem>
                                    <MenuItem value="MD">MD</MenuItem>
                                    <MenuItem value="MA">MA</MenuItem>
                                    <MenuItem value="MI">MI</MenuItem>
                                    <MenuItem value="MN">MN</MenuItem>
                                    <MenuItem value="MS">MS</MenuItem>
                                    <MenuItem value="MO">MO</MenuItem>
                                    <MenuItem value="MT">MT</MenuItem>
                                    <MenuItem value="NE">NE</MenuItem>
                                    <MenuItem value="NV">NV</MenuItem>
                                    <MenuItem value="NH">NH</MenuItem>
                                    <MenuItem value="NJ">NJ</MenuItem>
                                    <MenuItem value="NM">NM</MenuItem>
                                    <MenuItem value="NY">NY</MenuItem>
                                    <MenuItem value="NC">NC</MenuItem>
                                    <MenuItem value="ND">ND</MenuItem>
                                    <MenuItem value="MP">MP</MenuItem>
                                    <MenuItem value="OH">OH</MenuItem>
                                    <MenuItem value="OK">OK</MenuItem>
                                    <MenuItem value="OR">OR</MenuItem>
                                    <MenuItem value="PW">PW</MenuItem>
                                    <MenuItem value="PA">PA</MenuItem>
                                    <MenuItem value="PR">PR</MenuItem>
                                    <MenuItem value="RI">RI</MenuItem>
                                    <MenuItem value="SC">SC</MenuItem>
                                    <MenuItem value="SD">SD</MenuItem>
                                    <MenuItem value="TN">TN</MenuItem>
                                    <MenuItem value="TX">TX</MenuItem>
                                    <MenuItem value="UT">UT</MenuItem>
                                    <MenuItem value="VT">VT</MenuItem>
                                    <MenuItem value="VI">VI</MenuItem>
                                    <MenuItem value="VA">VA</MenuItem>
                                    <MenuItem value="WA">WA</MenuItem>
                                    <MenuItem value="WV">WV</MenuItem>
                                    <MenuItem value="WI">WI</MenuItem>
                                    <MenuItem value="WY">WY</MenuItem>
                                </Select>
                                {form.stateError && <FormHelperText>{form.stateError}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="standard"
                                margin="normal"
                                fullWidth
                                id="zip"
                                label="ZIP"
                                name="zip"
                                placeholder="ZIP"
                                error={Boolean(form.zipError)}
                                helperText={form.zipError}
                                onChange={handleChange}
                                value={form.zip}
                            />
                        </Grid>
                    </>}

                    <Grid item xs={12} sm={12}>
                        <div className="file-upload-area">
                            <p className="file-upload-label">{imageFile ? imageFile.name : 'Upload receipt here'}</p>
                            <label htmlFor="btn-upload">
                                <input
                                    id="btn-upload"
                                    name="btn-upload"
                                    style={{display: 'none'}}
                                    type="file"
                                    onChange={handleFileSelect}
                                />
                                <Button
                                    className="file-btn"
                                    variant="outlined"
                                    component="span">
                                    Choose {imageFile && 'A Different '} File
                                </Button>
                            </label>
                        </div>
                    </Grid>
                </Grid>

                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Button className="submit-verify-btn"
                            variant="contained"
                            sx={{mt: 3, ml: 1}}
                            color="primary"
                            onClick={handleRegister}
                    >
                        FINALIZE
                    </Button>
                </Box>
            </div>
            <p className="payment-footer-text">
                Thank you for submitting your information. We will send you money in the next 3 business days. Please
                login
                to check status or send us a message if you need to fix any issues in your payment information
                or have problems please email us at help@calitopia.co</p>
        </>
    )
}
const ErrorHelperText = styled.p`
  color: #f44336;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  letter-spacing: 0.03333em;
  line-height: 1.66;
  margin: 3px 0em 0em 2.5em;
  text-align: left;
`;
