import { Card, FormLabel, MenuItem, Switch, TextField } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import InputTags from './tags';

const SurveyQuestionForm = (props) => {
  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    rebateId,
    questionId,
    deleteSurveyQuestion,
    surveyQuestion,
    questionTypeList,
    values
  } = props;

  return (
    <Card style={{ padding: 15, margin: '10px 0px', background: 'transparent' }}>
      <div>
        <div style={{ textAlign: 'center' }}>
          <DeleteOutline
            style={{ cursor: 'pointer' }}
            color="error"
            onClick={() => deleteSurveyQuestion(rebateId, questionId)}
          />
        </div>
        <div>
          <TextField
            margin="dense"
            variant="outlined"
            label="Question Type"
            placeholder="Select Question Type"
            select
            fullWidth
            id={`rebates[${rebateId}].surveyQuestions[${questionId}].type`}
            value={surveyQuestion.type}
            onChange={(e) => {
              setFieldValue(
                `rebates[${rebateId}].surveyQuestions[${questionId}].type`,
                e.target.value
              );
            }}
            defaultValue={surveyQuestion.type}
            onBlur={handleBlur}
            helperText={
              touched.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.type
                ? errors.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.type
                : ''
            }
            error={
              touched.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.type &&
              Boolean(errors.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.type)
            }
            required
          >
            {questionTypeList.map((result) => (
              <MenuItem key={result[rebateId]} value={result}>
                {result}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            placeholder="Enter question"
            id={`rebates[${rebateId}].surveyQuestions[${questionId}].question`}
            label="Question"
            value={surveyQuestion.question}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.question
                ? errors.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.question
                : ''
            }
            error={
              touched.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.question &&
              Boolean(errors.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.question)
            }
            required
          ></TextField>
        </div>
        <div>
          <FormLabel>Required</FormLabel>
          <Switch
            checked={surveyQuestion.required}
            onChange={(e) => {
              setFieldValue(
                `rebates[${rebateId}].surveyQuestions[${questionId}].required`,
                e.target.checked
              );
            }}
          />
        </div>
        {(surveyQuestion.type === 'Select' || surveyQuestion.type === 'Multi-Select') && (
          <div>
            <InputTags
              setFieldValue={setFieldValue}
              rebateId={rebateId}
              questionId={questionId}
              values={values}
              errors={errors}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default SurveyQuestionForm;
