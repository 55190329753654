import React from "react";
import YoutubeEmbed from "./YouTubeEmbed";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";

const Pillar = ({ content, embedID }) => {
  const breakPoint1 = useMediaQuery("(min-width: 1075px)");
  return (
    <div
      style={{
        maxWidth: breakPoint1 ? "488px" : "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "100%", marginBottom: "10px" }}>
        <YoutubeEmbed embedId={embedID}></YoutubeEmbed>
      </div>
      {content.map((item, key) => {
        return (
          <div key={key}>
            <div>
              <p
                style={{
                  fontSize: "22px",
                  color: "#541B6F",
                  textDecoration: "underline",
                }}
              >
                {item.linkText}
              </p>
            </div>
            <div>
              <p style={{ fontSize: "16px" }}>{item.details}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const MoreInfo = ({ brandDetails }) => {
  const breakPoint1 = useMediaQuery("(min-width: 1075px)");
  const breakPoint2 = useMediaQuery("(min-width: 768px)");

  const content = [
    {
      linkText: "Purablis",
      details:
        "This is where you will put your links and media. This is just placeholder text for now. Equilibrium provides micro-doses of cannabinoids that your body can process while keeping your mind clear and focused.",
    },
    {
      linkText: "Purablis",
      details:
        "This is where you will put your links and media. This is just placeholder text for now. Equilibrium provides micro-doses of cannabinoids that your body can process while keeping your mind clear and focused.",
    },
    {
      linkText: "Purablis",
      details:
        "This is where you will put your links and media. This is just placeholder text for now. Equilibrium provides micro-doses of cannabinoids that your body can process while keeping your mind clear and focused.",
    },
  ];
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "1050px",
          display: "flex",
          flexDirection: breakPoint2 ? "row" : "column",
          padding: breakPoint1 ? "" : "15px",
          justifyContent: "space-between",
        }}
      >
        <Pillar
          content={content}
          embedID={"https://www.youtube.com/embed/oU1zWttsC30"}
        ></Pillar>
        <Pillar
          content={content}
          embedID={"https://www.youtube.com/embed/x0ktt3ytEjY"}
        ></Pillar>
      </div>
    </div>
  );
};

export default MoreInfo;
