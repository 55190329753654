import React, {useContext, useEffect, useState} from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import {defaultStepRegistrationForm, StepRegistrationForm, validateStepRegistrationForm} from "../shared/validation";
import styled from "styled-components";
import {ErrorText} from "../shared/styled-components";
import firebase from "firebase";
import {toast} from "react-toastify";
import {createOrUpdateUser, getUserByPhone, postEmailList, postTextList} from "../shared/api";
import {sendMessage} from "../utils/northText";
import {CircularProgress} from "@material-ui/core";
import parsePhoneNumber, {isValidNumberForRegion} from "libphonenumber-js";
import InputAdornment from "@material-ui/core/InputAdornment";

export default function RegistrationStep({setStep}) {
    const [form, setForm] = useState<StepRegistrationForm>(defaultStepRegistrationForm);
    const [recaptchaVerifier, setRecaptchaVerifier] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [otpDisable, setOtpDisable] = useState(true);
    const [otpResponse, setOtpResponse] = useState({});

    useEffect(() => {
        let d = new firebase.auth.RecaptchaVerifier('recaptcha-container',{
            'size': 'invisible',
        });
        setRecaptchaVerifier(d)
    }, []);

    const handleRegister = () => {
        if (form.phoneNumber) {
            const phone = parsePhoneNumber(form.phoneNumber, 'US')
            if (phone && phone.number) {
                form.phoneNumber = phone.number;
            }
        }
        setForm({ ...form, isSubmitting: true });
        if (form.isSubmitting) {
            return;
        }
        const newForm = validateStepRegistrationForm(form);
        if (Boolean(newForm.error)) {
            // @ts-ignore
            setForm({ ...newForm, isSubmitting: false })
            return;
        }

        const {error, isSubmitting, ...newUserData } = newForm;
        setLoading(true)
        // @ts-ignore
        return otpResponse.confirm(newForm.otp).then((result) => {

            setLoading(false)
            if (result.additionalUserInfo.isNewUser) {
                return createOrUpdateUser(result.user?.uid, newUserData).then(async () => {
                    if (form.isPromotionsEmailChecked) {
                        await postEmailList(form.email, 'Calitopia');
                    }
                    if (form.isPromotionsEmailChecked) {
                        await postTextList(form.phoneNumber, 'Calitopia');
                        await sendMessage(form.phoneNumber);
                    }
                }).then(() => {
                    setTimeout(() => setStep(2), 100)
                })
            } else {
                return toast.error("Mobile number is already registered with us.",{position: "top-right"})
            }
        }).catch((err) => {
            setLoading(false)

            return toast.error(err.message,{position: "top-right"})
        })

    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
        setForm({ ...form, [e.target.id]: e.target.value, [`${e.target.id}Error`]: null, error: null });

    const transformEvent = (e) => ({
        ...e,
        target: {
            ...e.target,
            id: e.target.id,
            value: e.target.checked
        }
    });

    const verifyMobile = () => {
        if (!form.phoneNumber) return toast.error("Please enter phone number.", {position: "top-right"})
        setLoading(true)
        const phone = parsePhoneNumber(form.phoneNumber, 'US')
        if (isValidNumberForRegion(form.phoneNumber, 'US') && phone && phone.number) {
            return getUserByPhone(phone.number).then((isUserExist) => {
                setLoading(false)
                if (isUserExist.length) return toast.error("Mobile number is already registered with us.", {position: "top-right"})
                return firebase.auth().signInWithPhoneNumber(phone.number, recaptchaVerifier)
                    .then((result) => {
                        setOtpDisable(false)
                        setOtpResponse(result)
                        return toast.success("Otp has been sent successfully.", {position: "top-right"})
                    })
                    .catch(err => {
                        if (err?.code === 'auth/invalid-phone-number') {
                            return toast.error("Please enter valid phone number.", {position: "top-right"})
                        }
                    });
            })
        } else {
            return toast.error("Please enter valid phone number.", {position: "top-right"})
        }
    }

    const handleCheckTerms = (e) => handleChange(transformEvent(e));


    return (
        <>
            <Box sx={{mt: 3, mb: 3}}>
                <Typography className="page-label" variant="h4" align="center" color="rgb(0, 160, 176)" fontWeight="600">
                    Contact Information
                </Typography>
            </Box>
            <div className="contact-info-main">
                <p className="form-head-p">
                    Make an account to receive your rebate payments, check the status of your rebates, and learn
                    about future discounts. We will not share your contact or banking information.
                </p>

                <Grid container spacing={2}>
                    {Boolean(form.error) && typeof form.error === 'string' ? <ErrorText>{form.error}</ErrorText> : null}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            value={form.firstName}
                            label="First Name"
                            name="firstName"
                            id="firstName"
                            onChange={handleChange}
                            placeholder="Your first name"
                            required
                            error={Boolean(form.firstNameError)}
                            helperText={form.firstNameError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            value={form.lastName}
                            label="Last Name"
                            name="lastName"
                            id="lastName"
                            onChange={handleChange}
                            placeholder="Your last name"
                            required
                            error={Boolean(form.lastNameError)}
                            helperText={form.lastNameError}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            value={form.email}
                            label="Email"
                            name="email"
                            id="email"
                            onChange={handleChange}
                            placeholder="Your email address"
                            type="email"
                            required
                            error={Boolean(form.emailError)}
                            helperText={form.emailError}
                        />
                    </Grid>

                    <Grid item xs={7} sm={9}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            value={form.phoneNumber}
                            label="Phone Number"
                            name="phoneNumber"
                            id="phoneNumber"
                            onChange={handleChange}
                            placeholder="Phone Number"
                            type="text"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        +1
                                    </InputAdornment>
                                ),
                            }}
                            error={Boolean(form.phoneNumberError)}
                            helperText={form.phoneNumberError}
                        />
                        <span className="input-subtext">
                            We will not send you spam or share your information
                        </span>
                    </Grid>
                    <Grid item xs={5} sm={3} className="otp-btn-section">
                        <Button className="send-otp-btn" variant="contained" sx={{mt: 3, ml: 1}} color="primary" onClick={verifyMobile}>
                            {loading ? <CircularProgress style={{color: "#f1f1f1"}} size={20} /> : 'Send Otp'}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            disabled={otpDisable}
                            error={Boolean(form.otpError)}
                            fullWidth
                            helperText={form.otpError}
                            id="otp"
                            label="Otp"
                            onChange={handleChange}
                            value={form.otp}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} className="terms-checkbox-first">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={form.isPromotionsEmailChecked}
                                    name="agreenemt"
                                    id="isPromotionsEmailChecked"
                                    color="primary"
                                    onChange={handleCheckTerms}
                                />
                            }
                            label="I'd like to receive cannabis related promotions from Calitopia via email"
                        />
                    </Grid>
                    <Grid item xs={12} className="terms-checkbox">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={form.isPromotionsTextChecked}
                                    name="agreenemt"
                                    id="isPromotionsTextChecked"
                                    color="primary"
                                    onChange={handleCheckTerms}
                                />
                            }
                            label="I'd like to receive cannabis related promotions from Calitopia via text"
                        />
                    </Grid>
                    <Grid item xs={12} className="terms-checkbox">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={form.isTermsAndConditionsChecked}
                                    name="agreenemt"
                                    color="primary"
                                    required
                                    id="isTermsAndConditionsChecked"
                                    onChange={handleCheckTerms}
                                />
                            }

                            label={
                                <span>I agree with Calitopia's{' '}<a className="hyperlink-main">Terms and Conditions and Privacy Policy</a></span>
                            }
                        />
                    </Grid>
                    {form.isTermsAndConditionsCheckedError && (
                        <ErrorHelperText>{form.isTermsAndConditionsCheckedError}</ErrorHelperText>
                    )}
                </Grid>

                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Button disabled={loading} className="submit-verify-btn" variant="contained" sx={{mt: 3, ml: 1}}
                            color="primary" onClick={handleRegister}>
                        {loading ? <CircularProgress style={{color: "#f1f1f1"}} size={20} /> : 'SUBMIT AND VERIFY YOUR NUMBER'}
                    </Button>
                </Box>
            </div>
        </>
    )
}

const ErrorHelperText = styled.p`
  color: #f44336;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  letter-spacing: 0.03333em;
  line-height: 1.66;
  margin: 3px 0em 0em 2.5em;
  text-align: left;
`;
