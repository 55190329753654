// External Dependencies
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
// import { useParams } from 'react-router-dom';

// Internal Dependencies
import { BrandPageContainer, FlexRow, PageContainer } from 'shared/styled-components';
import { useParams } from 'react-router';
import { getBrandBySlug } from 'shared/api';
import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CopyLink from '../assets/copy-link.svg';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";
import video1 from "../assets/purablisVideo.mp4";
import hqDefault from "../assets/hqdefault.png";

import './styles.scss';
import RebateItem from './rebateItem';
import SocialIcon from './SocialIcon';
import { getClickableLink } from 'utils/getClickableLink';

const NotFoundContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  @media (max-width: 726px) {
    font-size: 20px;
  }
`;

const AdvertisementContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5px 0px;
  gap: 10px;
  & h4 {
    margin: 0px;
    padding: 0px;
  }
  & h3 {
    margin-bottom: 10px;
    padding: 0px;
  }
  & div {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
`;



// Component Definition
const BrandOverview = () => {
  const { brandName } = useParams<{ brandName: string }>();
  const [loading, setLoading] = useState(true);
  const [isExist, setIsExist] = useState(false);
  const [brandDetails, setBrandDetails] = useState<any>('');

  const [usefulLinks, setUsefulLinks] = useState([]);

  const [images, setImages] = useState([]);

  function renderThumbnail(item) {
    return <span className="image-gallery-thumbnail-inner"> <video style={{
      verticalAlign: 'middle',
      lineHeight: 0,
    }} className="image-gallery-image" src={item.embedUrl}></ video></span >
  }

  function renderVideo(item) {
    if (item.type === 'youtube') {
      item.thumbnail = 'https://img.youtube.com/vi/i-swPnrbOWo/hqdefault.jpg';
      return <iframe className="image-gallery-image" src="https://www.youtube.com/embed/i-swPnrbOWo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    }
    // const canvas = document.createElement("canvas");
    // const video = document.createElement("video");
    // // this is important
    // video.autoplay = true;
    // video.muted = true;
    // video.src = item.embedUrl;
    // video.allowFullScreen = true;


    // video.onloadeddata = () => {
    //   let ctx = canvas.getContext("2d");
    //   canvas.width = video.videoWidth;
    //   canvas.height = video.videoHeight;
    //   ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    //   video.pause();
    //   item.thumbnail = canvas.toDataURL("image/png");
    // };
    // console.log("Video", video)
    // return <video />;
    return <video
      controls
      style={{
        maxHeight: '100%',
        width: '100%',
        objectFit: 'fill'
      }}
      src={item.embedUrl}
      className="image-gallery-image"
    // allowFullScreen={true}
    />
  }



  const onSlide = (e) => {
  }

  const someComponent = props => {
    // console.log(props.someProps.objectKey)
    return <div>{/* {props.someProps.objectKey} */}</div>;
  };

  useEffect(() => {
    getBrandBySlug(brandName)
      .then((result) => {
        setIsExist(true);
        setBrandDetails(result);
        const filterLinks = result.links.filter((result) => !['facebook', 'youtube', 'instagram', 'linkedin', 'twitter'].includes(result.title.toLowerCase()) && result.url.toLowerCase().indexOf('https://www.youtube.com/embed') < 0)
        setUsefulLinks(filterLinks)
        if (result.gallery)
          setImages(result.gallery.map((result) => {
            if (result.isImage) {
              return {
                original: result.link,
                thumbnail: result.link
              }
            }
            else {
              return {
                original: '',
                thumbnail: result.link,
                embedUrl: result.link,
                description: 'Rendering Videos',
                renderItem: renderVideo,
                renderThumbInner: renderThumbnail
              }
            }
          }))
      })
      .catch((err) => {
        console.error("Error", err);
        setIsExist(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [brandName]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', width: '100%' }}>
        <CircularProgress style={{ marginTop: 10 }} />
      </div>
    );
  }

  if (!isExist && !loading) {
    return <NotFoundContainer>404 not found</NotFoundContainer>;
  }

  if (brandDetails || isExist) {
    return (
      <BrandPageContainer>
        <div className="brandoverview">
          <div style={{ flexGrow: 1 }}>
            <div className="brandoverview-header">
              <img alt="brand logo" src={brandDetails.logo} />
              <p>{brandDetails.name}</p>
            </div>
            <div className="brandoverview-description">
              <div className="brandoverview-description-gallery">
                <ImageGallery
                  items={images}
                  showBullets={true}
                  // showIndex={true}
                  showThumbnails={true}
                  lazyLoad={true}
                  showPlayButton={false}
                  renderCustomControls={someComponent}
                  thumbnailPosition="bottom"
                  onSlide={onSlide}
                  showFullscreenButton={images.length > 0 ? true : false}
                />
              </div>
              <div className="brandoverview-description-introduction">
                <p className="brandoverview-heading">About</p>
                {/* {brandDetails?.links?.filter((result) => result.title.toLowerCase().indexOf('website') > -1).map((result) =>
                  <p>Website: <a href={result.url} target="_blank" rel="noreferrer">{result.url}</a></p>
                )} */}
                {
                  brandDetails?.website && <p><a style={{ wordBreak: 'break-word' }} href={getClickableLink(brandDetails.website)} target="_blank" rel="noreferrer">{brandDetails.website}</a></p>
                }

                {brandDetails?.location?.length > 0 &&
                  <div className="brandoverview-location">
                    {brandDetails?.location?.map((result) => (
                      <span>
                        <a style={{ wordBreak: 'break-word' }} href={getClickableLink(result)} target="_blank" rel="noreferrer">Where to Find Us</a>
                      </span>
                    ))}
                  </div>
                }
                <div className="brandoverview-social-sites">
                  {brandDetails?.links?.filter((result) => ['facebook', 'youtube', 'instagram', 'linkedIn', 'twitter'].includes(result.title.toLowerCase())).map((result) => {
                    return (
                      <div>
                        <a href={result.url} target="_blank" rel="noreferrer">
                          <SocialIcon title={result.title} />
                        </a>
                      </div>
                    )
                  })}
                </div>
                <p className="desktop-description">{brandDetails?.description}</p>
              </div>
              <p className="mobile-description">{brandDetails?.description}</p>
            </div>
            <div style={{ width: '100%', height: 1, marginTop: 10, background: '#BABABA' }}></div>
            <div className="brandoverview-rebates-ad">
              <div className="brandoverview-rebates ">
                {brandDetails?.rebates?.length > 0 ?
                  <>
                    <p
                      style={{ marginBottom: 25 }} className="brandoverview-heading">Rebates</p>
                    <div>
                      {brandDetails.rebates.map(({ name, title, discountImage }) => (
                        <Box marginTop="1em">
                          <RebateItem name={name} title={title} discountImage={discountImage}></RebateItem>
                        </Box>
                      ))}
                    </div>
                  </>
                  : <>
                    <p
                      className="brandoverview-heading">Rebates</p>
                    <p style={{
                      textAlign: 'left',
                      fontFamily: 'Montserrat',
                      fontStyle: 'normal',
                      fontWeight: 400,
                    }}>No any rebates </p>
                  </>}
              </div>
              <div className="brandoverview-ads">
                {brandDetails?.links?.filter((result) => result.url.indexOf('embed') > -1).length > 0 || usefulLinks.length > 0 ? <AdvertisementContainer>
                  <h3 className="brandoverview-heading">Useful Links</h3>
                  <div style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    {brandDetails?.links?.filter((result) => result.url.indexOf('embed') > -1).map((result) => {
                      return (
                        <iframe src={result.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      )
                    })}  </div></AdvertisementContainer> : <>
                  <h3
                    className="brandoverview-heading">Useful Links</h3>
                  <p style={{
                    textAlign: 'left',
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}>No any useful links </p>
                </>}

                <AdvertisementContainer>
                  {usefulLinks?.map((result: any) => {
                    return (
                      <div>
                        <a style={{ color: 'rgba(84, 27, 111, 1)', fontWeight: 600, textDecoration: 'underline' }} href={result.url} target="_blank" rel="noreferrer">
                          {result.title}
                        </a>
                      </div>
                    );
                  })}
                </AdvertisementContainer>
              </div>
            </div>
          </div>
        </div >
      </BrandPageContainer >
    );
  }
};

export default BrandOverview;
