import React from "react";
import plurablisIcon from "../assets/plurablisIcon.png";

const PlurabisBannerIcon = ({details}) => {
  return (
    <span style={{ zIndex: 3 }}>
      <img
        style={{
          height: "95px",
        }}
        src={details?.logo}
        alt={"Unable to load"}
      ></img>
    </span>
  );
};

export default PlurabisBannerIcon;
