// External Dependencies
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Link,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

// Internal Dependencies
import { SessionContext } from 'session/SessionContext';
import { FormGroupHeader, PageContainer } from 'shared/styled-components';

// Component Definition
const UserRebateStatuses = () => {
  const history = useHistory();
  const { userId } = useContext(SessionContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userRebates, setUserRebates] = useState<any>([]);

  useEffect(() => {
    const getRebateClaimings = (userId) =>
      firebase.firestore()
        .collection("RebateClaimings")
        .where('userId', '==', userId)
        // .orderBy("dateCreated", "desc")
        .get()
        .then((querySnapshot) => {
          const data: Array<any> = [];
          querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
          });
          return data;
        })
        // TODO: Switch to orderBy after all rebate claimings are standardized
        .then(rebates => rebates.sort((a, b) => {
          const dateA = Date.parse(a.dateCreated);
          const dateB = Date.parse(b.dateCreated);
          return dateA < dateB ? 1
            : dateA === dateB ? 0
              : -1;
        }))
        .then(rebates => setUserRebates(rebates))
        .then(() => setIsLoading(false));


    if (userId) {
      setIsLoading(true);
      getRebateClaimings(userId);
      return;
    }

    setIsLoading(false);
  }, [userId]);

  if (isLoading) {
    return (
      <PageContainer>
        <p>Getting your rebates</p>
        <CircularProgress />
      </PageContainer>
    );
  }

  if (!userRebates.length) {
    return (
      <PageContainer>
        <p>No rebates found</p>
      </PageContainer>
    );
  }

  const createTableRow = ({ brandName, id, dateCreated, status }) => (
    <TableRow style={{ cursor: 'pointer' }} onClick={() => history.push(`/rebate/status/${id}`)}>
      <TableCell>
        {new Date(dateCreated).toLocaleDateString()}
      </TableCell>
      <TableCell>
        {brandName}
      </TableCell>
      <TableCell>
        {status}
      </TableCell>
      <TableCell style={{ cursor: 'pointer' }}>
        Click for more Details
      </TableCell>
    </TableRow>
  );

  return (
    <PageContainer>
      <FormGroupHeader>My Rebate Submissions</FormGroupHeader>
      <Box mt="1em">
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {userRebates.map(createTableRow)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </PageContainer>
  );
}

export default UserRebateStatuses;
