// External Dependencies
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, TextField } from '@material-ui/core';
import { useParams } from 'react-router-dom';

// Internal Dependencies
import brandLogoUrls from 'shared/brand-logos';

// Styles
const BoldText = styled.span`
  font-weight: 600;
`;

const Box = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 16px;
`;

const Description = styled.p`
  font-family: Raleway;
  text-align: left;
`;

const ErrorText = styled.p`
  color: red;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled(Button)`
  &.MuiButtonBase-root {
    background-image: linear-gradient(-60deg, #B3FFAB 0%, #8ACFC6 50%, #8ACFC6 55%, #12FFF7 100%);
    color: white;
    margin: 16px auto 0 auto;
    width: 200px;
  }
`;

const VerifyContainer = styled.div`
  margin: auto;
  max-width: 450px;
  padding-top: 60px;
  text-align: center;

  & > h2 {
    color: #454545;
    font-family: Raleway;
    font-size: 2em;
    font-weight: 400;
  }
`;

// Component Definition
const Verify = () => {
  const { id } = useParams<{ id: string }>();
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [status, setStatus] = useState<string>('prompt');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCode(e.target.value?.substr(0, 5));
  }

  const brandLogoUrl = id ? brandLogoUrls[id.toLowerCase()] : null;

  const checkCode = () => {
    if (!Boolean(code)) {
      setError('Missing code');
      return;
    }

    setStatus('passed');
  };

  const validContent = (
    <div>
      <img
        alt=""
        src="https://assets.website-files.com/57db122d3ef63fcd43068b5a/5f7e0f55893b3f4a85b99ddf_ABX_VAPE_1G_BBKush-no-cart-p-500.jpeg"
        height="200px"
        width="200px"
      />
      <p>Success!</p>
      <p>Budtender, Please proceed to give John Smith the $5.00 discount in Treez which will turn the 42.33 price to $37.33 </p>
      <Button
        onClick={() => {
          setError('');
          setStatus('prompt');
        }}
        variant="outlined"
      >
        Check another coupon
      </Button>
    </div>
  );

  const defaultContent = (
    <div>
      {Boolean(error) && <ErrorText>{error}</ErrorText>}
      <Description>Enter the coupon code for <BoldText>{id}</BoldText> to check that it is valid.</Description>
      <FormContainer>
        <TextField
          label="Coupon Code"
          onChange={handleChange}
          variant="outlined"
          value={code}
        />
        <SubmitButton
          color="primary"
          onClick={checkCode}
          variant="contained"
        >
          Submit
        </SubmitButton>
      </FormContainer>
    </div>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <VerifyContainer>
        <h2>Coupon Checker</h2>
        <Box>
          {brandLogoUrl && <img alt="brand logo" src={brandLogoUrl} width="100%" />}
          {status === 'prompt' ? defaultContent : validContent}
        </Box>
      </VerifyContainer>
    </div>
  );
}

export default Verify;
