import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField
} from '@material-ui/core';
import { useState } from 'react';

const SelectCompanyBrand = (props) => {
  const {
    selectedBrand,
    setSelectedBrand,
    selectedCompany,
    setSelectedCompany,
    companyList,
    brandList,
    handleAddCompany,
    handleAddBrand,
    setAddCompanyButton,
    handleSubmit,
    values,
    oldValues,
    setOldValues,
    initialValues,
    errors
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [slaveSelectedBrand, setSlaveSelectedBrand] = useState('');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: 10,
          alignItems: 'center',
          '@media screen and (max-width:767px)': {
            flexWrap: 'wrap'
          }
        }}
      >
        <TextField
          label="Select Company"
          select
          fullWidth
          value={selectedCompany}
          onChange={(e) => {
            setSelectedCompany(e.target.value);
          }}
          margin="dense"
          variant="outlined"
        >
          <MenuItem value={''}>Select Company</MenuItem>
          {companyList.map((result) => (
            <MenuItem key={result.id} value={result.name}>
              {result.name}
            </MenuItem>
          ))}
        </TextField>
        <Button
          style={{ minWidth: 'fit-content', marginTop: 4 }}
          onClick={handleAddCompany}
          variant="outlined"
        >
          Add Company
        </Button>
        <TextField
          label="Select Brand"
          select
          value={selectedBrand}
          onChange={(e) => {
            if (JSON.stringify(values) === JSON.stringify(oldValues)) {
              setSelectedBrand(e.target.value);
              if (e.target.value) {
                setAddCompanyButton(false);
              }
            } else {
              setSlaveSelectedBrand(e.target.value);
              setShowDialog(true);
            }
          }}
          margin="dense"
          fullWidth
          variant="outlined"
        >
          <MenuItem value="">Select Brand</MenuItem>
          {brandList.map((result) => (
            <MenuItem key={result.id} value={result.name}>
              {result.name}
            </MenuItem>
          ))}
        </TextField>
        <Button
          style={{ minWidth: 'fit-content', marginTop: 4 }}
          variant="outlined"
          onClick={handleAddBrand}
        >
          Add Brand
        </Button>
      </Box>
      <Dialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>Do you want to save the changes made to {selectedBrand}?</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowDialog(false);
              setSelectedBrand(slaveSelectedBrand);
            }}
          >
            No
          </Button>
          <Button
            onClick={async () => {
              try {
                handleSubmit();
                setTimeout(() => {
                  if (Object.keys(errors).length === 0) {
                    setSelectedBrand(slaveSelectedBrand);
                  }
                }, 1000);
              } catch (e) {
                console.log('Error', e);
              } finally {
                setShowDialog(false);
              }
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectCompanyBrand;
