// External Dependencies
import React from 'react';

const defaultRebateContext = {
  error: null,
  isSubmitting: false,
  paymentForm: {},
  rebateSelectionForm: {
    error: null,
    selectedRebates: []
  },
  receiptUploadForm: {
    imageFile: null
  },
  setError: _ => { },
  setPaymentForm: _ => { },
  setRebateSelectionForm: _ => { },
  setReceiptUploadForm: _ => { },
  surveyForm: [],
  setSurveyForm: _ => { },
  surveyError: false,
  setSurveyError: _ => { },
  setIsSurveyDeleteDialog: _ => { }
};

export const RebateContext = React.createContext(defaultRebateContext);
