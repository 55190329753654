// External Dependencies
import firebase from 'firebase/app';
import React, {createContext, useCallback, useEffect, useState} from 'react';

// Internal Dependencies
import {validateLoginForm, LoginForm} from 'shared/validation';
import {toast} from "react-toastify";
import parsePhoneNumber from "libphonenumber-js";

/**
 * SessionContext is responsible for managing the login status of our app and user data: what is
 * provided to us from firebase's authentication process and what we store in the User collection
 * of our firebase store.
 */

export const defaultValue: {
    firebaseAuth: firebase.User | null;
    isGetting: boolean;
    signIn: (phoneNumber: string, otp: string, otpResponse: any) => Promise<void>;
    signOut: () => void;
    reloadUser: () => Promise<void>;
    user: firebase.firestore.DocumentData | undefined | null;
    userId: string | undefined | null;
} = {
    firebaseAuth: null,
    isGetting: true,
    signIn: (phoneNumber, otp, otpResponse: any) => new Promise<void>(() => {
    }),
    signOut: () => {
    },
    reloadUser: () => new Promise<void>(() => {
    }),
    user: null,
    userId: null,
};

export const SessionContext = createContext(defaultValue);

// Wraps our validation function as a promise for better readability.
// TODO: Check if it makes more sense to move validateLoginForm here. Depends on login functionality
// between users and budtender process.
const validateLogin = (phoneNumber: any, otp: string) =>
    new Promise<void>((resolve, reject) => {
        const newForm = validateLoginForm({phoneNumber, otp});
        if (newForm.error) {
            reject(newForm);
        }
        resolve();
    });

// Transform error into format used by UI
const isLoginForm = (obj: any): obj is LoginForm => {
    if ((obj as LoginForm)?.phoneNumber) {
        return true;
    }
    return false;
};

const transformError = (error) => {
    switch (error?.code) {
        case 'auth/user-not-found':
            return "No account found on this number. Please sign-up first."
        case 'auth/internal-error':
            return 'There was a problem logging in. Please try again later';
        case 'auth/wrong-password':
            return 'Invalid login';
        case 'auth/not-verified':
            return 'Please verify your email';
        case 'auth/invalid-verification-code':
            return 'Invalid verification code. Please try again.'
        case 'auth/invalid-phone-number':
            return 'Please enter valid phone number.'
        default:
            return 'There was a problem logging in. Please try again later';
    }
};

export const SessionContextProvider = ({children}) => {
    const [isGetting, setIsGetting] = useState<boolean>(true);
    const [firebaseAuth, setFirebaseAuth] = useState<firebase.User | null>(null);
    const [user, setUser] = useState<firebase.firestore.DocumentData | undefined | null>(null);
    const [isLoginFlow, setIsLoginFlow] = useState<boolean>(false);

    const beginSignInProcess = () =>
        new Promise<void>((resolve) => {
            // setIsGetting(true);
            resolve();
        });

    const endSignInProcess = () =>
        new Promise<void>((resolve) => {
            setIsGetting(false);
            resolve();
        });

    // Get user data from firestore
    const getUserData = useCallback(
        (id) =>
            firebase
                .firestore()
                .collection('Users')
                .doc(id)
                .get()
                // Update state with document data
                .then((doc) => {
                    if (!doc.exists) {
                        throw new Error(`No user found with id ${firebaseAuth?.uid}`);
                    }
                    setUser(doc.data());
                })
                .catch(console.log),
        [firebaseAuth]
    );

    const signIn = (phoneNumber: string, otp: string, otpResponse: any): Promise<void> => {
        // Notify app that we are getting credentials
        if (phoneNumber) {
            const phone = parsePhoneNumber(phoneNumber, 'US');
            return firebase.firestore().collection('Users')
                .where('phoneNumber', '==', phone?.number).get().then(result => {
                    if (result && result.docs && result.docs.length) {
                        if (!otp) throw {code: 'auth/invalid-verification-code'};
                        beginSignInProcess()
                            // Validate arguments before calling firebase
                            .then(() => validateLogin(phone?.number, otp))
                            // Get firebase user
                            .then(() => otpResponse.confirm(otp))
                            // Update state and extract user id
                            .then((newFirebaseAuthData) => {
                                if (!newFirebaseAuthData.additionalUserInfo.isNewUser) {
                                    // if (firebase.auth().currentUser?.emailVerified) {
                                    setFirebaseAuth(firebase.auth()?.currentUser);
                                    return newFirebaseAuthData?.user?.uid;
                                    // }
                                }
                                // newFirebaseAuthData.user?.sendEmailVerification();
                                // @ts-ignore
                                throw {code: 'auth/user-not-found'};
                            })
                            // Get user data from firestore
                            .then(getUserData)
                            .then(endSignInProcess)
                            .catch((error) => {

                                // setIsGetting(false);
                                // Is validation error: result is already formatted
                                // if (isLoginForm(error)) {
                                //     return Promise.reject(error);
                                // }
                                // Transform error into format used by UI
                                // return Promise.reject({
                                //     phoneNumber, phoneNumberError: null,
                                //     error: transformError(error),
                                //     otp,
                                //     otpError: null
                                // });
                                return toast.error(transformError(error), {position: "top-right"})
                            });
                    } else {
                        throw {code: 'auth/user-not-found'};
                    }
                }).catch((error) => {
                    return Promise.reject({
                        phoneNumber, phoneNumberError: null,
                        error: transformError(error),
                        otp,
                        otpError: null
                    });
                });
        } else {
            return Promise.reject({
                phoneNumber, phoneNumberError: true,
                error: transformError({code: "auth/invalid-phone-number"}),
                otp,
                otpError: null
            });
        }
    }


    const signOut = () =>
        firebase
            .auth()
            .signOut()
            .then(() => {
                setFirebaseAuth(null);
                setUser(null);
                window.location.reload();
            });

    const reloadUser = () => {
        return getUserData(firebaseAuth?.uid);
    }

    // Autologin if previous session is still valid
    useEffect(
        () =>
            firebase.auth().onAuthStateChanged(() => {
                // if (!firebase.auth().currentUser?.emailVerified) {
                //     setIsGetting(false)
                //     return;
                // }
                beginSignInProcess()
                    .then(() => getUserData(firebase.auth().currentUser?.uid))
                    .then(() => setFirebaseAuth(firebase.auth().currentUser))
                    .then(endSignInProcess);
            }),
        [getUserData]
    );

    const contextValue = {
        firebaseAuth,
        isGetting,
        signIn,
        signOut,
        reloadUser,
        user,
        userId: firebaseAuth?.uid
    };

    return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};
