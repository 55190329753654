// External Dependencies
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle
} from '@material-ui/core';
import styled from 'styled-components';

// Internal Dependencies
import CalitopiaSvg from 'shared/CalitopiaSvg';



// Component Definition
const AgeDialog = () => {
  const [showAgeDialog, setShowAgeDialog] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleClose = () => {
    if (isConfirmed) {
      setShowAgeDialog(false)
    }
  }

  const nodeRef = React.useRef(null);

  return (
    <Dialog
      disableEscapeKeyDown
      //onBackdropClick={() => {}}
      onClose={handleClose}
      open={showAgeDialog}
      ref={nodeRef}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        {/* @ts-ignore */}
        <CalitopiaSvg style={{ width: '75%' }} />
      </DialogTitle>
      <DialogContent style={{ fontSize: '1.5em', textAlign: 'center' }}>
        Are you over 21 years old?
      </DialogContent>
      <DialogActions>
        <DialogButton
          onClick={() => window.location.href = 'https://www.google.com'}
          variant="outlined"
        >
          No
        </DialogButton>
        <DialogButton
          onClick={() => {
            setIsConfirmed(true);
            setShowAgeDialog(false);
          }}
          variant="outlined"
        >
          Yes
        </DialogButton>
      </DialogActions>
  </Dialog>
  );
}

const DialogButton = styled(Button)`
  width: 100%;
`;

const DialogContent = styled(MuiDialogContent)`

`;

export default AgeDialog;
