// External Dependencies
import Button from '@material-ui/core/Button';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMore from '@material-ui/icons/ExpandMore';
import firebase from 'firebase/app';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { green } from '@material-ui/core/colors';
import { MenuProps } from '@material-ui/core/Menu';

// Internal Dependencies
import { ErrorText } from 'shared/styled-components';

// Styles
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1em;
  margin: 1em 0;
`;

const PaymentArea = styled.div`
  background-color: rgba(138, 207, 198, .1);
  border: solid 2px rgba(138, 207, 198, .7);
  border-radius: 2px;
  margin: 1em;
  padding: 0 .5em 1em .5em;
  & > h4 {
    color: #454545;
    margin: .5em 0;
  }
`;


interface StatusMenuProps extends MenuProps {
  status: string;
  onStatusChange: (string) => void;
}

const StatusMenuItem = ({ label, isSelected, ...otherProps }) => (
  <MenuItem {...otherProps}>
    {isSelected && <CheckCircle style={{ color: green[500], marginRight: 16 }}/>}
    {label}
  </MenuItem>
);

// TODO: Resolve console errors about ref
const StatusMenu = React.forwardRef(({ ref: ignoreRef, status, onStatusChange, ...menuProps }: StatusMenuProps, ref) => {
  return (
    // @ts-ignore
    <Menu {...menuProps} anchorEl={ref.current}>
      <StatusMenuItem
        isSelected={status === 'Pending'}
        label="Pending"
        onClick={() => onStatusChange('Pending')}
      />
      <StatusMenuItem
        isSelected={status === 'Approved'}
        label="Approve - Awaiting Payment"
        onClick={() => onStatusChange('Approved')}
      />
      <StatusMenuItem
        isSelected={status === 'Paid'}
        label="Approve - Paid"
        onClick={() => onStatusChange('Paid')}
      />
      <StatusMenuItem
        isSelected={status === 'Rejected'}
        label="Reject"
        onClick={() => onStatusChange('Rejected')}
      />
    </Menu>
  );
});

const PaymentInstructions = ({ userData, refund, paymentMethod }) => {
  const {
    firstName,
    lastName,
    venmoUsername,
    primaryLine,
    secondaryLine,
    city,
    state,
    zip
  } = userData;

  const prettyAmount = Number(refund).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <PaymentArea>
      <h4>{prettyAmount}</h4>
      {paymentMethod === 'Venmo' ? (
        <span>Venmo: {venmoUsername}</span>
      ) : (
        <p>
          {firstName} {lastName}<br/>
          {primaryLine} {secondaryLine} <br/>
          {city}, {state} {zip}
        </p>
      )}
    </PaymentArea>
  );
};

// Component Definition
const RebateClaimingsDialog = ({
  rebateData,
  onClose,
  onRefetch,
  ...dialogProps
}) => {
  const ref = useRef();

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>(rebateData.status);
  const [rebateUser, setRebateUser] = useState<any>(null);

  const {
    refund,
    brandName,
    date,
    id,
    paymentMethod,
    receiptUrl,
    userId,
  } = rebateData;

  // Get data of user who submitted rebate
  const getUserData = useCallback(() =>
    firebase.firestore()
      .collection('Users')
      .doc(userId)
      .get()
      .then(doc => {
        if (doc.exists) {
          return setRebateUser(doc.data());
        } else {
          throw new Error(`Unable to find User with id ${userId}`);
        }
      })
      .catch(err => setError(err.message))
      .finally(() => setIsLoading(false)), [userId]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setIsOpen(false);
  };

  const saveChanges = () => {
    const db = firebase.firestore();
    db.collection('RebateClaimings').doc(id).update({
      status: status
    })
    .then(() => {
      onRefetch();
      onClose();
    })
    .catch(err => {
      console.log('Error saving change to rebate', err);
    });
  };

  const userContent = isLoading ? (
    <div>
      <CircularProgress />
      <p>Getting user data...</p>
    </div>
  ) : Boolean(error) ? (
    <ErrorText>
      Error getting user data:<br/>{error}
    </ErrorText>
  ) : (
    <PaymentInstructions
      refund={refund}
      paymentMethod={paymentMethod}
      userData={rebateUser}
    />
  );

  return (
    // @ts-ignore
    <Dialog onClose={onClose} {...dialogProps}>
      <DialogTitle>Update Rebate Claiming</DialogTitle>
      <DialogContent style={{ width: '20em', padding: 0 }}>
        <FlexRow>
          <div>{brandName}</div>
          <div>{date}</div>
        </FlexRow>
        {userContent}
        <FlexRow>
          <a href={receiptUrl} target='_blank' rel="noreferrer">View Receipt</a>
          <div>
            {/* @ts-ignore */}
            <Button
              ref={ref}
              onClick={() => setIsOpen(true)}
              variant="outlined"
            >
              {status}<ExpandMore />
            </Button>
            <StatusMenu
              ref={ref}
              status={status}
              open={isOpen}
              onClose={() => setIsOpen(false)}
              onStatusChange={handleStatusChange}
            />
          </div>
        </FlexRow>
        {/* <FlexRow>
          <TextareaAutosize rowsMin={3} placeholder="Notes" />
        </FlexRow> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={saveChanges}>Save Changes</Button>
      </DialogActions>
    </Dialog>
  );
}

export default RebateClaimingsDialog;
