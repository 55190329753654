import React from "react";
import vectorImage from "../assets/mainBannerVector.png";

const MainBannerBG = () => {
  const mainBannerBgStyles = {
    width: "670px",
    height: "100%",
  };
  return (
    <div style={mainBannerBgStyles}>
      <img src={vectorImage} alt="Unable to load"></img>
    </div>
  );
};

export default MainBannerBG;
