import { useContext, useEffect, useState } from 'react';
import { withStyles, Card, Box, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import {
  BrandValidationSchema,
  CouponSchema,
  LinkSchema,
  LocationSchema,
  RebateSchema,
  StoreValidationSchema,
  SurveyQuestionSchema
} from 'utils/validationSchema';
import { deleteCouponFromDB, deleteParticipatingStores, fetchStores, getAllBrands, getAllCategories, getCollectionId, getParticipatingStoresByCouponId, postCoupon, postParticipatingStores, postStore } from 'shared/api';
import { ToastContainer, toast } from 'react-toastify';
import BrandDetailsForm from './StoreDetailsForm';
import { styles } from './styles';
import { SessionContext } from 'session/SessionContext';
import { Redirect } from 'react-router';
import SelectStore from './SelectStore';
import { get, result } from 'lodash';
import moment from 'moment';



const Stores = (props) => {
  const { classes } = props;


  // to hold and set the list of brands
  const [storeList, setStoreList] = useState([]);

  // to hold and set the list of categories
  const [categoryList, setCategoryList] = useState([]);

  // status of AddBrandButton
  const [addStoreButton, setAddStoreButton] = useState(false);

  // to track selected brand
  const [selectedStore, setSelectedStore] = useState('');

  const [brandList, setBrandList] = useState([]);


  const [deletedCoupons, setDeletedCoupons] = useState([]);

  const [deletedParticipatingStores, setDeletedParticipatingStores] = useState([]);

  /**
   * Initial values while creating brand
   */
  const initialValues = {
    name: '',
    type: '',
    website: '',
    phone: '',
    live: false,
    description: '',
    location: [],
    slugs: [],
    links: [{ title: '', url: '' }],
    logo: '',
    coupons: [],
    gallery: [],
    address: {
      address1: '',
      address2: '',
      postalCode: '',
      city: '',
      coordinates: {
        latitude: '',
        longitude: ''
      }
      , state: '',
      country: 'United States'
    }
  };

  const [oldValues, setOldValues] = useState(initialValues);

  /**
   * Formik form handling with validationSchema: BrandValidationSchema
   */
  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    setValues,
    setFieldValue,
    handleSubmit,
    setSubmitting,
    isSubmitting
  } = useFormik({
    initialValues,
    validationSchema: StoreValidationSchema,
    onSubmit: async (values) => {
      try {
        values.couponList = values.coupons.map((result, index) => {
          values.coupons[index].startDate = result?.startDate?._isAMomentObject ? result?.startDate.format('YYYY-MM-DD hh:mm:ss') : moment(result.startDate).format('YYYY-MM-DD hh:mm:ss');
          values.coupons[index].expirationDate = result?.expirationDate?._isAMomentObject ? result?.expirationDate.format('YYYY-MM-DD hh:mm:ss') : moment(result.expirationDate).format('YYYY-MM-DD hh:mm:ss');
          return result.name.toLowerCase()
        });
        values.brandedCoupons = values.coupons.filter((result) => result.type === 'Brand');
        values.nonBrandedCoupons = values.coupons.filter((result) => result.type === 'Store');
        await addStore(values);
      }
      catch (err) {
        console.error(err);
      }
    },
    enableReinitialize: true
  });

  /**
   * To add new brand to the db.
   */
  const addStore = async (values) => {
    try {
      setSubmitting(true);
      const { id: storeId } = await postStore({ values });
      // let storeId = values.id || await getCollectionId('Store');
      const participatingStores = values.brandedCoupons.map((result) => ({ brandId: result.brandId, storeId: storeId, couponId: result.id }))
      await Promise.all(deletedParticipatingStores.map(async (result) => await deleteParticipatingStores(result)))
      await Promise.all(participatingStores.map(async (result) => await postParticipatingStores(result)))
      await Promise.all(deletedCoupons.map(async (result) => await deleteCouponFromDB(result)))
      await Promise.all(values.nonBrandedCoupons.map(async (result) => await postCoupon({ id: await getCollectionId('Coupon'), ...result, storeId: storeId, type: 'Store' })))
      setAddStoreButton(false);
      return toast.success('Store created/updated successfully',{position: "top-right"});
    } catch (err) {
      return toast.error(err,{position: "top-right"});
    } finally {
      await getStoreList();
      setSubmitting(false);
    }
  };

  /**
   * To delete the particular link
   * @param {number} id - Link Id
   */
  const deleteLink = (id) => {
    setFieldValue(
      'links',
      values.links.filter((_, index) => index !== id)
    );
  };

  /**
   * To delete the particular rebate
   * @param {number} id Rebate Id
   */
  const deleteCoupon = (index) => {
    const { id: storeId } = values;
    const { id: couponId, type: couponType } = values.coupons[index];
    if (couponId && couponType === 'Store') {
      setDeletedCoupons((prev) => [...prev, couponId]);
    }
    if (couponId && couponType === 'Brand' && storeId) {
      setDeletedParticipatingStores((prev) => [...prev, `${storeId}-${couponId}`]);
    }
    const result = values.coupons;
    result.splice(index, 1);
    setFieldValue(
      'coupons',
      result
    );
  };

  const getBrandList = async () => {
    try {
      const result = await getAllBrands(false)
      setBrandList(result);
    } catch (err) {
      toast.error('Error fetching brand list',{position: "top-right"});
    }
  };

  const getCategoryList = async () => {
    try {
      const result = await getAllCategories();
      setCategoryList(result);
    }
    catch (err) {
      toast.error('Error fetching brand list',{position: "top-right"});
    }
  };

  /**
   * To add the new link by validating whether previous links validate to the LinkSchema or not.
   */
  const addLink = () => {
    if (!errors.links) {
      if (values.links.length > 0) {
        Promise.all(values.links.map((link) => LinkSchema.validate(link))).then(() => {
          setValues((prev) => ({
            ...prev,
            links: [
              ...prev.links,
              {
                title: '',
                url: ''
              }
            ]
          }));
        });
      } else {
        setValues((prev) => ({
          ...prev,
          links: [
            {
              title: '',
              url: ''
            }
          ]
        }));
      }
    }
  };

  /**
   * To add the new coupon by validating whether previous coupon validate to the CouponSchema or not.
   */
  const addCoupon = async () => {
    if (!errors.coupons) {
      values.coupons.map(async (coupon) => {
        await CouponSchema.validate(coupon);
      })
      const couponId = await getCollectionId('Coupon')
      setFieldValue(`coupons`, [{
        id: couponId,
        name: '',
        categoryId: '',
        description: '',
        amountType: '',
        amount: '',
        image: '',
        startDate: moment().toDate(),
        expirationDate: moment().add(10, 'days').toDate(),
      }, ...values.coupons])
    }
  };

  /**
   * To fetch all the stores from db
   */
  const getStoreList = async () => {
    try {
      const result = await fetchStores();
      setStoreList(result);
    } catch (err) {
      toast.error('Error fetching brand list',{position: "top-right"});
    }
  };

  useEffect(() => {
    getStoreList();
    getBrandList();
    getCategoryList();
  }, [])

  useEffect(() => {
    if (selectedStore) {
      setValues(storeList.find((result) => result.name === selectedStore));
      setOldValues(storeList.find((result) => result.name === selectedStore));
    } else {
      setValues({ ...initialValues });
      setOldValues({ ...initialValues, });
    }
  }, [selectedStore]);


  /**
   * To reset the selected company,brand value when clicking add brand button
   */
  const handleAddStore = () => {
    setAddStoreButton(true);
    setFieldValue('store', '');
    setSelectedStore('');
  };

  /**
   * To add new locations
   */
  const addLocation = () => {
    if (values?.location?.length > 0) {
      Promise.all(values?.location?.map((loc) => LocationSchema.validate(loc))).then(() => {
        setValues((prev) => ({
          ...prev,
          location: [...prev.location, '']
        }));
      });
    } else {
      setValues((prev) => ({
        ...prev,
        location: ['']
      }));
    }
  };

  /**
   * To delete the particular location
   * @param {number} id - Location ID
   */
  const deleteLocation = (id) => {
    setFieldValue(
      'location',
      values.location.filter((_, index) => index !== id)
    );
  };
  const otherProps = {
    setFieldValue: setFieldValue,
    values: values,
    handleChange: handleChange,
    handleBlur: handleBlur,
    touched: touched,
    errors: errors,
    addLink: addLink,
    addCoupon: addCoupon,
    deleteLink: deleteLink,
    deleteCoupon: deleteCoupon,
    isSubmitting: isSubmitting,
    classes: classes,
    handleSubmit: handleSubmit,
    selectedStore: selectedStore,
    setSelectedStore: setSelectedStore,
    storeList: storeList,
    handleAddStore: handleAddStore,
    oldValues: oldValues,
    setOldValues: setOldValues,
    initialValues: initialValues,
    addLocation: addLocation,
    deleteLocation: deleteLocation,
    brandList: brandList,
    categoryList: categoryList,
  };

  const { user, isGetting } = useContext(SessionContext);

  const loadingComponent = (
    <Box display="flex">
      <Box m="auto">
        <CircularProgress />
      </Box>
    </Box>
  );

  if (isGetting) {
    return loadingComponent;
  }

  if (!isGetting && !user?.isAdmin) {
    return <Redirect to="/" />;
  }
  return (
    <div className={classes.container}>
      <h2>Store Page</h2>
      {/* {JSON.stringify(values)}

      {JSON.stringify(errors)} */}

      <Card className={classes.card}>
        <SelectStore {...otherProps} />
        {(addStoreButton || values.name) && (
          <BrandDetailsForm {...otherProps} />
        )}
      </Card>
    </div>
  );
};

export default withStyles(styles)(Stores);
