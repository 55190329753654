import React, { useEffect, useState } from 'react';
import MainBanner from './MainBanner';
import MainHeader from './MainHeader';
import MediaPlayer from './MediaPlayer';
import MiddleNav from './MiddleNav';
import NavPlaceholder from './NavPlaceholder';
import RebateSection from './RebateSection';
import MoreInfo from './MoreInfo';
import { getBrandBySlug } from '../shared/api';
import { useParams } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import './style.scss';

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const Main = () => {
  const { brandName } = useParams<{ brandName: string }>();
  const [loading, setLoading] = useState(true);
  const [isExist, setIsExist] = useState(false);
  const [brandDetails, setBrandDetails] = useState<any>('');

  const [usefulLinks, setUsefulLinks] = useState([]);

  const [images, setImages] = useState([]);
  useEffect(() => {
    getBrandBySlug(brandName)
      .then((result) => {
        setIsExist(true);
        setBrandDetails(result);
        const filterLinks = result.links.filter(
          (result) =>
            !['facebook', 'youtube', 'instagram', 'linkedin', 'twitter'].includes(
              result.title.toLowerCase()
            ) && result.url.toLowerCase().indexOf('https://www.youtube.com/embed') < 0
        );
        setUsefulLinks(filterLinks);
        if (result.gallery)
          setImages(
            result.gallery.map((result) => {
              if (result.isImage) {
                return {
                  original: result.link,
                  thumbnail: result.link,
                };
              } else {
                return {
                  original: '',
                  thumbnail: result.link,
                  embedUrl: result.link,
                  description: 'Rendering Videos',
                  renderItem: renderVideo,
                  renderThumbInner: renderThumbnail
                };
              }
            })
          );
      })
      .catch((err) => {
        console.error('Error', err);
        setIsExist(false);
      })
      .finally(() => {
        setLoading(false);
      });
      
  }, [brandName]);

  function renderThumbnail(item) {
    return (
      <span className="image-gallery-thumbnail-inner">
        {' '}
        <video
          style={{
            verticalAlign: 'middle',
            lineHeight: 0
          }}
          className="image-gallery-image"
          src={item.embedUrl}
        ></video>
      </span>
    );
  }

  function renderVideo(item) {
    if (item.type === 'youtube') {
      item.thumbnail = 'https://img.youtube.com/vi/i-swPnrbOWo/hqdefault.jpg';
      return (
        <iframe
          className="image-gallery-image"
          src="https://www.youtube.com/embed/i-swPnrbOWo"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    }
    // const canvas = document.createElement("canvas");
    // const video = document.createElement("video");
    // // this is important
    // video.autoplay = true;
    // video.muted = true;
    // video.src = item.embedUrl;
    // video.allowFullScreen = true;

    // video.onloadeddata = () => {
    //   let ctx = canvas.getContext("2d");
    //   canvas.width = video.videoWidth;
    //   canvas.height = video.videoHeight;
    //   ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    //   video.pause();
    //   item.thumbnail = canvas.toDataURL("image/png");
    // };
    // console.log("Video", video)
    // return <video />;
    return (
      <video
        controls
        style={{
          maxHeight: '100%',
          width: '100%',
          objectFit: 'fill'
        }}
        src={item.embedUrl}
        className="image-gallery-image"
        // allowFullScreen={true}
      />
    );
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', width: '100%' }}>
        <CircularProgress style={{ marginTop: 10 }} />
      </div>
    );
  }

  return (
    <div className="brand-rebate-main" style={{ width: '100%' }}>
      {/*<NavPlaceholder type={"Navbar"}/>*/}
      <MainBanner brandDetails={brandDetails} />
      <MiddleNav brandDetails={brandDetails} />
      <MainHeader text={'Rebates'} top={''} bottom={''} />
      <RebateSection brandDetails={brandDetails} />
      <div
        style={{
          display: 'flex'
        }}
      >
        <ImageGallery items={images} />;
      </div>

      {/* <MediaPlayer images={images} /> */}
      <MainHeader text={'More Info'} top={'15px'} bottom={'15px'} />
      <MoreInfo brandDetails={brandDetails} />
      {/*<NavPlaceholder type={"Footer"} margin={"30px"}/>*/}
    </div>
  );
};

export default Main;
