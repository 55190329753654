// External Dependencies
import './index.css';
import App from './App';
import firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'react-toastify/dist/ReactToastify.css';
import GA4React from 'ga-4-react';
const sgMail = require('@sendgrid/mail');

// TODO: This config is public by design. We need to restrict usage to calitopia.co
// https://medium.com/@devesu/how-to-secure-your-firebase-project-even-when-your-api-key-is-publicly-available-a462a2a58843
// const firebaseConfig = process.env.REACT_APP_ENV === 'production' ? JSON.parse(process.env.REACT_APP_FIREBASE_CREDENTIALS_PROD) : JSON.parse(process.env.REACT_APP_FIREBASE_CREDENTIALS_DEV);

const local={
  apiKey: "AIzaSyDIiSJwqySY51UcFL5p0zpj7B9FRmBuWtg",
  authDomain: "calitopia.firebaseapp.com",
  projectId: "calitopia",
  storageBucket: "calitopia.appspot.com",
  messagingSenderId: "651191384303",
  appId: "1:651191384303:web:785d0b5e9ee5f88ec2831a",
  measurementId: "G-TLXZF067ST"
};
const stage = {
  apiKey: "AIzaSyD1U4tGPDYK4WFofL3p_-tRH2H3lS62AHY",
  authDomain: "calitopia-staging.firebaseapp.com",
  projectId: "calitopia-staging",
  storageBucket: "calitopia-staging.appspot.com",
  messagingSenderId: "176289097759",
  appId: "1:176289097759:web:4c03e9c812e09ecef9cd2a",
  measurementId: "G-YNND0XPK4D"
};
const firebaseConfig = stage

const sendGridConfig = {  
  apiKey: process.env.REACT_APP_SENDER_API_KEY
};

sgMail.setApiKey(sendGridConfig.apiKey);

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  if (process.env.REACT_APP_ENV === 'local') {
    console.log('Using local firebase functions');
    firebase.functions().useEmulator('Calitopia', 5001);
  }
} else {
  console.log('Firebase already initialized', firebase.apps);
}

const ga4react = new GA4React('G-JE0KZL9J0B');
(async (_) => {
  await ga4react
    .initialize()
    .then((res) => console.log('Analytics Success.'))
    .catch((err) => console.log('Analytics Failure.'))
    .finally(() => {
      ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById('root')
      );
    });
})();




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
