// External Dependencies
import React /*, { useEffect, useState }*/ from 'react';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import firebase from 'firebase/app';
import styled from 'styled-components';

// Internal Dependencies
import CalitopiaSvg from 'shared/CalitopiaSvg';
import { PageContainer } from 'shared/styled-components';

// const Brands = styled.div`
//   margin-top: 1em;
// `;

// const Brand = styled.div`
//   display: inline-grid;
//   height: 8em;
//   padding: 2em 1em;
//   width: 20%;
// `;

// const Img = styled.img`
//   vertical-align: middle;
//   width: 100%;
// `

const Box = styled.div`
  margin: 0 auto;
  padding: 6em 3em;

  & * a {
    color: #6cada5;
    font-size: 1.25em;
    font-weight: bold;
    text-decoration: none;
  }

  & * p {
    color: #454545;
    font-family: Raleway;
    font-size: 1.5em;
    font-weight: 400;
  }
`;

// Component Definition
const LandingPage = () => {
  // const [brands, setBrands] = useState<Array<any>>([]);
  // const [isFetching, setIsFetching] = useState<boolean>(true);

  // const fetchBrands = () => {
  //   const db = firebase.firestore();
  //   db.collection('Brands')
  //     .get()
  //     .then((querySnapshot) => {
  //       const dbBrands: Array<any> = [];
  //       querySnapshot.forEach((doc) => {
  //         let data = doc.data();
  //         dbBrands.push({ name: data.name, url: data.url, logo_url: data.logo_url });
  //       });
  //       setBrands(dbBrands);
  //       setIsFetching(false);
  //     })
  //     .catch((err) => {
  //       // TODO: Handle error case
  //     });
  // };

  // useEffect(fetchBrands, []);
  // useEffect(() => console.log(brands), [brands]);

  return (
    <PageContainer>
      <Box>
        {/* @ts-ignore */}
        <CalitopiaSvg height={94} width={240} style={{ margin: '4em 0 1em 0', width: '100%' }} />
        <section>
          <p>Welcome to the future of promotions. Please contact us for more information.</p>
          <a style={{ color: '#00A0B0' }} href="mailto:info@calitopia.co">info@calitopia.co</a>
        </section>
        {/* <hr/>
        <Brands key='brands'>
          {isFetching ? (
            <CircularProgress />
            ) : (
            brands.map((b) => { return(
              <Brand key={b.name}>
                <a href={b.url}>
                  <Img src={b.logo_url} alt={b.name} />
                </a>
              </Brand>
            )})
          )}
        </Brands> */}
      </Box>
    </PageContainer>
  );
};

export default LandingPage;
