import React, {useContext, useEffect, useRef, useState} from 'react';
import {withStyles, Card, Box, CircularProgress, TextField, Button, Grid, Typography} from '@material-ui/core';
import {styles} from './styles';
import {SessionContext} from 'session/SessionContext';
import {Redirect} from 'react-router';
import {useHistory, useParams} from "react-router-dom";
import {defaultAddEditSurveyForm, validateAddEditSurveyForm} from "../shared/validation";
import {AddEditSurveyForm} from "../shared/validation";
import {createOrUpdateSurvey, getSurveyDetails} from "../shared/api";
import {toast} from "react-toastify";
import {Cancel} from "@material-ui/icons";

const Tags = ({data, handleDelete}) => {
    return (
        <Box className="keyword-tags">
            <Typography>{data}</Typography>
            <Cancel style={{cursor: 'pointer'}}
                    onClick={() => {
                        handleDelete(data);
                    }}
            />
        </Box>
    );
};

const AddEditSurvey = (props) => {
    let {surveyId} = useParams<{ surveyId: string }>();
    const [form, setForm] = useState<AddEditSurveyForm>(defaultAddEditSurveyForm);
    const history = useHistory();
    const redirect = (path) => () => history.push(path);
    const {user, isGetting} = useContext(SessionContext);
    const {classes} = props;
    const [value, setValue] = useState<any>('');
    const tagRef = useRef();

    const handleChange = (e: { target: { id: string; value: any } }): any =>
        setForm({...form, [e.target.id]: e.target.value, [`${e.target.id}Error`]: null, error: null});

    useEffect(() => {
        if (surveyId) {
            getSurveyDetails(surveyId).then((result) => {
                if (result.length){
                    // @ts-ignore
                    setForm({...form, surveyId: result[0]?.id, surveyName: result[0]?.surveyName, keywords: result[0]?.keywords})
                } else {
                    toast.error('Survey not found.', {position: "top-right"});
                    setTimeout(() => {
                        history.push('/admin/survey-list')
                    },1)
                }
            })
        }
    }, [])

    /**
     * To add new company to the db
     */
    const addEditSurvey = async () => {
        setForm({...form, isSubmitting: true});
        if (form.isSubmitting) {
            return;
        }
        const newForm = validateAddEditSurveyForm(form);
        if (Boolean(newForm.error)) {
            // @ts-ignore
            setForm({...newForm, isSubmitting: false})
            return;
        }

        return createOrUpdateSurvey(form).then(() => {
            // @ts-ignore
            setForm({...newForm, isSubmitting: false})
            toast.success('Survey created successfully', {position: "top-right"});
            setTimeout(() => {
                history.push('/admin/survey-list')
            },1)
        }).catch(({err}) => {
            // @ts-ignore
            setForm({...newForm, isSubmitting: false})
            return toast.error(err.message, {position: "top-right"});
        });
    };

    const handleDelete = (value) => {
        const newtags = form?.keywords?.filter((val) => val !== value);
        setForm({...form, keywords: newtags});
    };
    const handleOnSubmit = (e) => {
        setValue(e.target.value);
        if (e.keyCode === 13 && e.target.value.trim().length > 0) {
            // @ts-ignore
            setForm({...form, keywords: [...form?.keywords, tagRef?.current.value.trim()]})
            // @ts-ignore
            tagRef.current.value = '';
            setValue('');
        }
    };

    const loadingComponent = (
        <Box display="flex">
            <Box m="auto">
                <CircularProgress/>
            </Box>
        </Box>
    );

    if (isGetting) {
        return loadingComponent;
    }

    if (!isGetting && !user?.isAdmin) {
        return <Redirect to="/"/>;
    }
    return (
        <div className={classes.container}>
            <h2>Add Edit Survey Record</h2>

            <Card className={classes.card}>
                <form onSubmit={() => 0}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                placeholder="Enter survey name"
                                label="Survey Name"
                                id="surveyName"
                                name="surveyName"
                                required
                                error={Boolean(form.surveyNameError)}
                                helperText={form.surveyNameError}
                                onChange={handleChange}
                                value={form.surveyName}
                                fullWidth
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                placeholder="Enter survey id"
                                label="Survey Id"
                                id="surveyId"
                                name="surveyId"
                                required
                                error={Boolean(form.surveyIdError)}
                                helperText={form.surveyIdError}
                                onChange={handleChange}
                                value={form.surveyId}
                                fullWidth
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                inputRef={tagRef}
                                fullWidth
                                multiline
                                value={value}
                                variant="outlined"
                                label="Keywords"
                                margin="dense"
                                type="text"
                                id="keywords"
                                name="keywords"
                                onKeyDown={handleOnSubmit}
                                onChange={handleOnSubmit}
                                placeholder={form?.keywords?.slugs?.length || 0 < 5 ? 'Enter keywords' : ''}
                                InputProps={{
                                    startAdornment:
                                        form?.keywords.length > 0
                                            ? form?.keywords?.map((data, index) => {
                                                // @ts-ignore
                                                return <Tags data={data} handleDelete={handleDelete} key={index}/>;
                                            })
                                            : ''
                                }}
                                helperText={form.keywordsError ? form.keywordsError : ''}
                                error={!!form.keywordsError}
                            />
                        </Grid>
                    </Grid>
                    <div style={{textAlign: 'center', marginTop: '2em'}}>
                        <Button onClick={addEditSurvey} variant="outlined" color="primary">
                            {form.isSubmitting ? <CircularProgress size={20}/> : 'SAVE'}
                        </Button>
                        <Button style={{marginLeft: '1em'}} onClick={redirect('/admin/survey-list')} variant="outlined"
                                color="primary">
                            BACK
                        </Button>
                    </div>
                </form>
            </Card>
        </div>
    );
};

// @ts-ignore
export default withStyles(styles)(AddEditSurvey);
