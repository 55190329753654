// External Dependencies
import { Button, Popover, Typography } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridRowsProp
} from '@material-ui/data-grid';
import React, { useState } from 'react';
import { sumBy } from 'lodash';

// Internal Dependencies
import RebateClaimingsDialog from './RebateClaimingsDialog';
import SurveyResultDialog from './SurveyResultDialog';

// @ts-ignore
const renderCurrencyCell = (params: GridCellParams) =>
  params.value ? (
    <span>
      {Number(params.value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })}
    </span>
  ) : (
    <span>Unknown</span>
  );

const renderReceiptCell = (params: GridCellParams) =>
  params.value ? (
    // @ts-ignore
    // <a href={params.value || ''} target='_blank' rel='noreferrer'>
      <span style={{cursor: "pointer"}}>View</span>
    // </a>
  ) : (
    <span>None</span>
  );

const RenderUserId = (params) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return params.row.userId ? (
    <>
      <label
        aria-describedby={'simple-popover'}
        onClick={(e) => {
          handleClick(e);
          e.stopPropagation();
        }}
        style={{ width: '100%' }}
      >
        {params.row.userId.slice(0, 4)}..
      </label>
      <Popover
        id={'simple-popover'}
        open={open}
        onClose={() => handleClose()}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Typography style={{ padding: 7 }}>{params.row.userId}</Typography>
      </Popover>
    </>
  ) : (
    <></>
  );
  // (<label title={params.id}>{params.id.slice(0,5)}...</label>):<label></label>
};

const RenderSurvey = (params: GridCellParams) => {
  const [show, setShow] = useState(false);
  // @ts-ignore
  if (params.value?.length > 0) {
    return (
      <>
        <label
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShow(true);
          }}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          Show
        </label>
        {show && <SurveyResultDialog surveyResult={params.value} show={show} setShow={setShow} />}
      </>
    );
  } else {
    return <label>None</label>;
  }
};

const columns: GridColDef[] = [
  { field: 'date', headerName: 'Date', width: 120 },
  { field: 'brandName', headerName: 'Brand', flex: 1 },
  { field: 'email', headerName: 'Email', width: 250 },
  {
    field: 'userId',
    headerName: 'User ID',
    width: 150,
    renderCell: RenderUserId
  },
  { field: 'phoneNumber', headerName: 'Phone no.', width: 180 },
  {
    field: 'refund',
    headerName: 'Amount',
    renderCell: renderCurrencyCell,
    width: 140
  },
  { field: 'paymentMethod', headerName: 'Payment Method', width: 150 },
  {
    field: 'receiptUrl',
    headerName: 'Receipt',
    renderCell: renderReceiptCell,
    width: 140
  },
  {
    field: 'surveyResult',
    headerName: 'Survey',
    renderCell: RenderSurvey,
    width: 140
  },
  { field: 'status', headerName: 'Status', width: 120 },
  { field: 'appDen', headerName: 'A/D', width: 120 },
];

const RebatesTable = ({ onRefetch, items }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentRebate, setCurrentRebate] = useState<any>(null);

  const hideModal = () => setIsOpen(false);
  const showModal = (params: GridRowParams) => {
    setCurrentRebate(params.row);
    setIsOpen(true);
  };

  // Object keys must match the "field" property of columns
  const rows: GridRowsProp = items.map(
    ({ dateCreated, venmoUsername, ...rebateData }) => ({
      ...rebateData,
      date: new Date(dateCreated).toLocaleDateString(),
      paymentMethod: Boolean(venmoUsername) ? 'Venmo' : 'Cash',
      // @ts-ignore
      appDen: `${sumBy(items, (rec) => { return (rec.status === 'Approved') && (rec.email === rebateData.email) })} /    ${sumBy(items, (rec) => { return (rec.status === 'Pending') && (rec.email === rebateData.email) })}`
    }),
  );

  return (
    <div>
      <DataGrid
        autoHeight
        columns={columns}
        onRowClick={(rebate) => showModal(rebate)}
        pageSize={10}
        rows={rows}
        getRowId={(row) => row.id}
      />
      {isOpen && Boolean(currentRebate) && (
        <RebateClaimingsDialog
          rebateData={currentRebate}
          onClose={hideModal}
          onRefetch={onRefetch}
          open={isOpen}
        />
      )}
    </div>
  );
};

export default RebatesTable;
