// External Dependencies
import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'firebase/auth';

// Internal Dependencies
import { AppContext } from './AppContext';
import { SessionContextProvider } from 'session/SessionContext';
// import CookieBanner from 'static/CookieBanner';
import Footer from 'shared/Footer';
import { ToastContainer } from 'react-toastify';
import Routes from 'Routes';
import { CircularProgress } from '@material-ui/core';


const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(0, 160, 176)"
    },
    secondary: {
      main: "rgb(0, 160, 176)"
    }
  }
});

// Component Definition
function App(props) {
  const [dialogType, setDialogType] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const hideModal = () => setIsOpen(false);
  const showModal = ({ type }) => {
    setDialogType(type);
    setIsOpen(true);
  };

  const showLoginModal = () => showModal({ type: 'login' });
  const showProfileModal = () => showModal({ type: 'profile' });
  const showSignUpModal = () => showModal({ type: 'signup' });
  const showForgotPasswordModal = () => showModal({ type: 'forgotPassword' });


  const contextValue = {
    appBar: {
      dialogType,
      isOpen,
      hideModal,
      showLoginModal,
      showProfileModal,
      showSignUpModal,
      showForgotPasswordModal
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={contextValue}>
        <SessionContextProvider>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
            <Router>
              <Routes />
              <div id="recaptcha-container"/>
            </Router>
          </div>
        </SessionContextProvider>
        <ToastContainer position="top-center" closeOnClick autoclose={5000} />
        {/* <CookieBanner /> */}
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
