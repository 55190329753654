// External Dependencies
import React, {useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from 'firebase/app';
import styles from 'styled-components';

// Internal Dependencies
import LinkSurveyListTable from './LinkSurveyListTable';
import {getAllBrands, getRebatesByBrandId} from "../shared/api";

const Container = styles.div`
  margin: 0 1em;
  width:100%;
  & > h2 {
    font-family: Raleway;
  }
`;

var meta = document.createElement('meta');
meta.setAttribute('robots', 'noindex,nofollow');
document.getElementsByTagName('head')[0].appendChild(meta);

// Component Definition
const LinkSurveyList = () => {
    const [items, setItems] = useState<Array<any>>([]);
    // const [brands, setBrands] = useState<Array<any>>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);

    const fetchSurveyList = () => {
        const db = firebase.firestore();
        db.collection('LinkedExternalSurvey')
            .get()
            .then((querySnapshot: any) => {
                const newItems: Array<any> = [];
                let promise = [];
                querySnapshot.forEach(async (doc) => {
                    // @ts-ignore
                    promise.push(new Promise<void>(async (resolve) => {
                        let brandName = '';
                        const r = await getRebatesByBrandId(doc.data().brand);
                        brandName = r[0].name;
                        newItems.push({id: doc.id, brandName, ...doc.data()});
                        console.log(newItems,'newItems')
                        resolve();
                    }));
                });
                Promise.all(promise).then(() =>{
                    setItems(newItems);
                    setIsFetching(false);
                })

            })
            .catch((err) => {
                // TODO: Handle error case
            });
    };

    // @ts-ignore
    useEffect(fetchSurveyList, []);

    return (
        <Container>
            <h2>Linked Survey List</h2>
            {isFetching ? (
                <CircularProgress/>
            ) : (
                <LinkSurveyListTable onRefetch={fetchSurveyList} items={items}/>
            )}
        </Container>
    );
};

export default LinkSurveyList;
