import { Box, Button, CircularProgress, FormLabel, Switch, TextField, MenuItem } from '@material-ui/core';
import FileUpload, { createFileName } from './FileUpload';
import LinkForm from './LinkForm';
import RebateForm from './CouponForm';
import styled from 'styled-components';
import SlugTags from './SlugTags';
import LocationForm from './LocationForm';
import { deleteFileFromFirestore, uploadImageToFirestore } from 'shared/api';
import { DeleteOutline } from '@material-ui/icons';
import { useState } from 'react';
import CouponForm from './CouponForm';

const LogoContainer = styled.div`
  flexdirection: row;
  display: flex;
  gap: 20px;
  padding: 10px 0px;
  & > div {
    flex: 1;
  }
`;

const states = ["Alaska", "Alabama", "Arkansas", "American Samoa", "Arizona", "California", "Colorado", "Connecticut", "District of Columbia", "Delaware", "Florida", "Georgia", "Guam", "Hawaii", "Iowa", "Idaho", "Illinois", "Indiana", "Kansas", "Kentucky", "Louisiana", "Massachusetts", "Maryland", "Maine", "Michigan", "Minnesota", "Missouri", "Mississippi", "Montana", "North Carolina", "North Dakota", "Nebraska", "New Hampshire", "New Jersey", "New Mexico", "Nevada", "New York", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Virginia", "Virgin Islands", "Vermont", "Washington", "Wisconsin", "West Virginia", "Wyoming"]

const StoreDetailsForm = (props) => {
  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    values,
    isSubmitting,
    handleSubmit,
    addLink,
    addCoupon,
    classes,
    addLocation
  } = props;


  const [isUploading, setIsUploading] = useState(false);
  const handleGallerySelect = async (e) => {
    if (e.target.files) {
      const type = e.target.files[0].type;
      const [name, ext] = e.target.files[0].name.split('.');

      let isImage = false;
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
      const fileName = `gallery-${name}${Math.floor(Math.random() * 2000)}.${ext}`;
      if (validImageTypes.includes(type)) {
        isImage = true;
      }
      setIsUploading(true);
      await uploadImageToFirestore(e.target.files[0], fileName, 'gallery')
        .then((url) => {
          const result = values.gallery ? [...values.gallery, {
            isImage, link: url, fileName: fileName
          }] : [{
            isImage, link: url, fileName: fileName
          }];
          setFieldValue('gallery', result);
        }).finally(() => {
          setIsUploading(false);
        })
    } else {

    }
  };

  const handleGalleryDelete = async (fileName) => {
    try {
      await deleteFileFromFirestore(fileName, 'gallery');
    }
    catch (err) {
      console.error("Error", err);
    }
    finally {
      const result = values.gallery.filter((result) => result.fileName !== fileName)
      setFieldValue('gallery', result);
    }
  }
  return (
    <div>
      <form
        onSubmit={() => {
          return 0;
        }}
      >
        <div>
          <FormLabel Label>Live</FormLabel>
          <Switch
            checked={values.live}
            onChange={(e) => {
              setFieldValue(`live`, e.target.checked);
            }}
          />
        </div>
        <div>
          <TextField
            label="Store Type"
            select
            value={values.type}
            id="type"
            name="type"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.type ? errors.type : ''}
            error={touched.type && Boolean(errors.type)}
            margin="dense"
            variant="outlined"
            fullWidth
          >
            <MenuItem value="">Select Type</MenuItem>
            {['Dispensary', 'Delivery'].map((result) => (
              <MenuItem key={result} value={result}>
                {result}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <TextField
            margin="dense"
            variant="outlined"
            placeholder="Enter store name"
            fullWidth
            id="name"
            label="Store Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
            required
          ></TextField>
        </div>
        <div>
          <TextField
            margin="dense"
            variant="outlined"
            placeholder="Enter website"
            fullWidth
            id="website"
            label="Website"
            value={values.website}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.website ? errors.website : ''}
            error={touched.website && Boolean(errors.website)}
            required
          ></TextField>
        </div>
        <div>
          <TextField
            multiline
            rows={5}
            margin="dense"
            variant="outlined"
            placeholder="Enter description"
            fullWidth
            id="description"
            label="Description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.description ? errors.description : ''}
            error={touched.description && Boolean(errors.description)}
            required
          ></TextField>
        </div>
        <div>
          <TextField
            margin="dense"
            variant="outlined"
            placeholder="Enter store phone"
            fullWidth
            id="phone"
            label="Phone"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.phone ? errors.phone : ''}
            error={touched.phone && Boolean(errors.phone)}
            required
          ></TextField>
        </div>
        <div className={classes.links}>
          <FormLabel>Address</FormLabel>
          <div className={classes.addressContainer}>
            {/* <TextField
              margin="dense"
              variant="outlined"
              placeholder="Select Country"
              id="address.country"
              label="Country"
              value={values?.address?.country}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched?.address?.country ? errors?.address?.country : ''}
              error={touched?.address?.country && Boolean(errors?.address?.country)}
              required
              disabled
            ></TextField> */}

            <TextField
              margin="dense"
              select
              variant="outlined"
              placeholder="Enter State"
              id="address.state"
              name="address.state"
              label="State"
              value={values.address?.state}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched?.address?.state ? errors?.address?.state : ''}
              error={touched?.address?.state && Boolean(errors?.address?.state)}
              required
            >
              <MenuItem value=''>Select State</MenuItem>
              {states.map((result) => (<MenuItem value={result} key={result}>{result}</MenuItem>))}
            </TextField>
            <TextField
              margin="dense"
              variant="outlined"
              placeholder="Enter City"
              id="address.city"
              label="City"
              value={values?.address?.city}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched?.address?.city ? errors?.address?.city : ''}
              error={touched?.address?.city && Boolean(errors?.address?.city)}
              required
            ></TextField>

            <TextField
              margin="dense"
              variant="outlined"
              placeholder="Enter Address1"
              id="address.address1"
              label="Address1"
              value={values?.address?.address1}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched?.address?.address1 ? errors?.address?.address1 : ''}
              error={touched?.address?.address1 && Boolean(errors?.address?.address1)}
              required
            ></TextField>
            <TextField
              margin="dense"
              variant="outlined"
              placeholder="Enter address2"
              id="address.address2"
              label="Address2"
              value={values?.address?.address2}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched?.address?.address2 ? errors?.address?.address2 : ''}
              error={touched?.address?.address2 && Boolean(errors?.address?.address2)}
              required
            ></TextField>

            <TextField
              margin="dense"
              variant="outlined"
              placeholder="Enter postal code"
              id="address.postalCode"
              label="Postal Code"
              value={values?.address?.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched?.address?.postalCode ? errors?.address?.postalCode : ''}
              error={touched?.address?.postalCode && Boolean(errors?.address?.postalCode)}
              required
            ></TextField>
            <TextField
              margin="dense"
              variant="outlined"
              placeholder="Enter latitude"
              id="address.coordinates.latitude"
              label="Latitude"
              value={values?.address?.coordinates?.latitude}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched?.address?.coordinates?.latitude ? errors?.address?.coordinates?.latitude : ''}
              error={touched?.address?.coordinates?.latitude && Boolean(errors?.address?.coordinates?.latitude)}
              required
            ></TextField>
            <TextField
              margin="dense"
              variant="outlined"
              placeholder="Enter longitude"
              id="address.coordinates.longitude"
              label="Longitude"
              value={values?.address?.coordinates?.longitude}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched?.address?.coordinates?.longitude ? errors?.address?.coordinates?.longitude : ''}
              error={touched?.address?.coordinates?.longitude && Boolean(errors?.address?.coordinates?.longitude)}
              required
            ></TextField>
          </div>
        </div>
        <LogoContainer>
          <FormLabel>Logo</FormLabel>
          <FileUpload
            setFieldValue={setFieldValue}
            name={values.logo && values.logo.split('?')[0].split('%2F')[1]}
            id="logo"
            text="Upload Logo"
            type="store"
          />
          {/* {values.logo ? <img alt="Logo" src={URL.createObjectURL(values.logo)} /> : ''} */}
        </LogoContainer>
        <div>
          <SlugTags {...props} />
        </div>
        <div className={classes.links}>
          <FormLabel>Locations</FormLabel>
          <div>
            <Button type="button" onClick={addLocation} variant="outlined" color="primary">
              Add Location
            </Button>
            {values?.location?.map?.((result, index) => (
              <LocationForm {...props} result={result} index={index} />
            ))}
          </div>
        </div>
        {touched.location && values.location.length === 0 && errors.location && (
          <p
            className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required MuiFormHelperText-marginDense"
            style={{ marginLeft: 0 }}
          >
            {errors.location}
          </p>
        )}

        <div className={classes.links}>
          <FormLabel>Links</FormLabel>
          <div>
            <Button type="button" onClick={addLink} variant="outlined" color="primary">
              Add Link
            </Button>
            <div>
              {values.links.map((result, index) => (
                <LinkForm {...props} result={result} index={index} />
              ))}
            </div>
          </div>
        </div>



        <Box sx={{ marginTop: 10, '& label': { marginRight: 10 } }}>
          <div className={classes.links} style={{ alignItems: 'center' }}>
            <FormLabel>Gallery</FormLabel>
            <label htmlFor={'gallery'}>
              <input onChange={handleGallerySelect} on type="file" id={'gallery'} style={{ display: 'none' }} accept="image/*,video/*" />
              <Button component="span" variant="outlined" color="primary">
                Upload
              </Button>
              <span style={{ marginLeft: 20, color: 'red' }}>{isUploading && 'Uploading...'}</span>
            </label>

          </div>

          <div style={{ display: 'flex', 'flexWrap': 'wrap', gap: 10, marginLeft: 50, marginTop: 10 }}>
            {values?.gallery?.map((result, index) => (<div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, border: '1px solid white', padding: '10px 10px', borderRadius: 5 }}>
              {result.isImage ? <img style={{ width: 250, height: 200, objectFit: 'contain' }} src={result.link} alt="gallery" /> : <video style={{ width: 250, height: 200, objectFit: 'cover' }} src={result.link} alt="gallery" controls />}
              <Button variant="text" onClick={() => handleGalleryDelete(result.fileName)}>
                <DeleteOutline color="error" />
              </Button>
            </div>))}
          </div>

        </Box>

        <Box sx={{ marginTop: 10, '& label': { marginRight: 10 } }}>
          <FormLabel>Coupons</FormLabel>
          <Button type="button" onClick={addCoupon} variant="outlined" color="primary">
            Add Coupon
          </Button>
        </Box>
        {values.coupons.map((result, rebateId) => (
          <CouponForm {...props} result={result} couponId={rebateId} />
        ))}
        <div style={{ textAlign: 'center', padding: 10 }}>
          <Button onClick={handleSubmit} variant="outlined" disabled={isSubmitting} color="primary">
            {isSubmitting ? <CircularProgress size={20} /> : values.id ? 'Save Changes' : 'Submit'}
          </Button>
        </div>
      </form>
    </div >
  );
};

export default StoreDetailsForm;
