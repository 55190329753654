// External Dependencies
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// Internal Dependencies
import { FormGroupHeader, PageContainer } from 'shared/styled-components';
import { getAllBrands } from 'shared/api';
import { Link } from 'react-router-dom';
import NatureImage from '../assets/redwoods-and-sequoias-page-banner-desktop.jpeg';
import RebateBg from '../assets/rebate-background.png';
import CalitopiaSvg from 'shared/CalitopiaSvg';
import Step1 from '../assets/step-1.png'
import Step2 from '../assets/step-2.png'
import Step3 from '../assets/step-3.png'
import Step4 from '../assets/step-4.png'
import './styles.scss';
import { RebateCard } from './RebateCard';
import AppBar from 'session/AppBar';


const RebatePageContainer = styled(PageContainer)`
  transform: translateY(-3.5em);
  @media (max-width: 812px) {
    width: 100%;
  }
`;

const Background = styled.div`
  background: url(${RebateBg}) no-repeat top center;
  background-size: cover;
  height: 20em;
  width: 100%;
  @media (max-width: 812px) {
    height: 8em;
  }
`;

const Banner = styled.div`
  transform: translateY(-3.5em);
`;

const Container = styled.div`
  display: inline-block;
  padding: 1em 0.2em 1em;
  width: 23%;
  @media (max-width: 1100px) {
    display: block;
    padding-left: 0em;
    width: 45%;
  }
  @media (max-width: 768px) {
    display: block;
    padding-left: 0em;
    width: 45%;
  }
  @media (max-width: 567px) {
    display: block;
    padding-left: 0em;
    width: 90%;
  }
`;

const Grid = styled.div`
  column-gap: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  transform: translateY(-3.5em);
  background: #89cfc622;
  padding: 1em;
  text-align: center;
  @media (max-width: 812px) {
    align-items: center;
    column-gap: 0em;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    justify-items: flex-start;
    row-gap: .5em;
    text-align: left;
  }
`;

const GridItem = styled.div<any>`
  @media (max-width: 812px) {
    align-self: center;
    display: flex;
  }
`;

const GridItemText = styled.span`
  flex: 1;
  text-align: left;
  vertical-align: super;
`;

const HorizontalScroll = styled.div`
  display: flex;
  @media (max-width: 812px) {
    display: initial;
  }
`;

const Logo = styled.img`
  margin: 0 auto;
  object-fit: contain;
  width: 70%;
  height:120px;
`;

const Step = styled.span`
  color: #757575;
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  margin: 0 auto;
  padding: 0.5em 0;
  width: 3em;
  @media (max-width: 812px) {
    font-size: 1em;
    display: initial;
    margin-right: 0.5em;
    flex-basis: 4em;
  }
`;

const StyledCard = styled(Card)`
  &.MuiCard-root {
    overflow: hidden;
    margin: 0;
    min-height: 20em;
    padding: 1em;
    width:90%;
  }
  @media (max-width: 480px) {
    &.MuiCard-root {
      padding: 1em;
    }
  }
`;

const StyledCalitopiaSvg = styled(CalitopiaSvg)`
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(50%, 50%);
  height: 50%;
  width: 50%;
`;

const BrandCard = ({ logo, name, description, rebates, location, expandAll, slugs }) => {
  const [showAbout, setShowAbout] = React.useState(false);

  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setExpand(expandAll)
  }, [expandAll])

  return (
    <Container>
      <StyledCard elevation={3} style={{ borderRadius: 5 }}>
        <List>
          <ListItem>
            <Link style={{ textAlign: 'center', display: 'block', width: '100%' }} to={`/brand/${slugs[0]}`}>
              <Logo
                alt={`${name} logo`}
                src={logo}
              />
            </Link>
          </ListItem>
          {rebates.length > 0 ? (
            <div className="tickets">
              {expand && rebates.map(({ title, name, discount }) => (
                <Link to={`r/${name}`} style={{ width: '100%', textDecoration: 'none' }}>
                  <RebateCard title={title} amount={discount} />
                </Link>
              ))}
              {!expand &&
                <Link to={`r/${rebates[0].name}`} style={{ width: '100%', textDecoration: 'none' }}>
                  <RebateCard title={rebates[0].title} amount={rebates[0].discount} />
                </Link>
              }</div>) : (
            <ListItemText>TBD</ListItemText>
          )
          }
          <div style={{ textAlign: 'center', cursor: 'pointer' }}>
            {expand ? <ExpandLess onClick={() => { setExpand(false) }} /> : <ExpandMore onClick={() => { setExpand(true) }} />}
          </div>
          {/* <ListItemButton onClick={handleClickAbout}>
            <ListItemText primary="About" />
            {showAbout ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={showAbout} timeout="auto" unmountOnExit>
            <p style={{ textAlign: 'left' }}>{description}</p>
          </Collapse>
          <Divider />
          <ListItemButton onClick={handleClickLocations}>
            <ListItemText primary="Locations" />
            {showLocations ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={showLocations} timeout="auto" unmountOnExit>
            {location?.map((result) => {
              const domain = (new URL(result)).hostname;
              return (
                <a href={result} target="__blank">
                  {domain}
                </a>
              );
            })}
          </Collapse> */}
        </List>
      </StyledCard>
    </Container >
  );
}

const RebateShowcase = () => {
  const [brands, setAllBrands] = useState<any>('');
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    getAllBrands().then((result) => setAllBrands(result));
  }, []);

  return (
    <div className="rebates-showcase">
      <div className="banner">
        <AppBar />
        <div className="banner-background"></div>
        <div className="banner-content">
          <h3>Happiness Is Within Reach</h3>
          <p>We’re a coupon platform & mobileapp custom built for the cannabis industry</p>
        </div>

      </div>
      {/* <Banner>
        <div style={{ background: 'rgba(25,45,40,0.7)' }}>
          <Background />
        </div> */}
      {/* <StyledCalitopiaSvg style={{ filter: 'drop-shadow(1px 2px 3px #555)' }} /> */}
      {/* </Banner> */}
      <div className="rebates-steps">
        <div className="rebates-step">
          <img src={Step1} alt="step1" />
          <div>
            <p>1</p>
            <label>Find deals for brands you love</label>
          </div>
        </div>
        <div className="rebates-step">
          <img src={Step2} alt="step2" />
          <div>
            <p>2</p>
            <label>Order from any dispensary or delivery service</label>
          </div>
        </div>
        <div className="rebates-step">
          <img src={Step3} alt="step3" />
          <div>
            <p>3</p>
            <label>Complete our rebate form with a picture of your receipt</label>
          </div>
        </div>
        <div className="rebates-step">
          <img src={Step4} alt="step4" />
          <div>
            <p>4</p>
            <label>Get money!</label>
          </div>
        </div>
      </div>
      <div className="rebates-container">
        <div className="rebates-container-heading">
          <h2>Check out these rebates from our partners!</h2>
          {expandAll ? <ExpandLess style={{ cursor: 'pointer' }} onClick={() => { setExpandAll(false) }} /> : <ExpandMore style={{ cursor: 'pointer' }} onClick={() => { setExpandAll(true) }} />}
        </div>

        <div className="rebates-list">
          {brands?.map?.((brandData, index) => (
            <BrandCard expandAll={expandAll} key={index} {...brandData} />
          ))}
        </div>
      </div>
    </div >
  );
};

export default RebateShowcase;
